import { cnUtils } from '@alife/cn-ui';
export function emitEvent(eventName, ...args) {
    const events = cnUtils?.events;
    if (events && eventName) {
        events?.emit(eventName, ...args);
    }
}
export function onEvent(eventName, func) {
    const events = cnUtils?.events;
    if (events && eventName && typeof func === 'function') {
        return events?.on(eventName, func);
    }
}
export function offEvent(eventName, func) {
    const events = cnUtils?.events;
    if (events && eventName) {
        return events?.off(eventName);
    }
}
export const CnFilterOnSearch = 'L2_CnFilter_onSearch';
// 筛选栏默认值准备完成的回调
export const CnFilterDefaultValueFinished = 'L2_CnFilterDefaultValueFinished';
