import { FeaturePosition } from '../types/feature-position';
import { getJSExpressionPrototype, getFirstOptions, isArrayNotEmpty, } from '../util/util';
import { getMixedSetterSnippet } from '../setter/statistic-setter';
import set from 'lodash/set';
import _round from 'lodash/round';
import _isNumber from 'lodash/isNumber';
export const componentLoopMap = {};
const summary = {
    title: '中心结果汇总',
    value: 'summary',
    position: [FeaturePosition.pie],
    getDefaultProps: () => {
        return {
            title: '共计',
            decimal: 2,
        };
    },
    getPrototypeList: () => {
        return [
            {
                title: '汇总标题',
                name: 'title',
                setter: 'CnI18nSetter',
            },
            {
                title: '保留小数位数',
                name: 'decimal',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        min: 0,
                    },
                },
            },
        ];
    },
    handleFeature: (config) => {
        const { featureItemConfig, options } = config || {};
        const { options: itemOptions } = featureItemConfig || {};
        const statics = [
            {
                type: 'text',
                style: {
                    text: itemOptions?.title || '共计',
                    x: '50%',
                    y: '50%',
                    dy: -10,
                    fontSize: 20,
                    textAlign: 'center',
                    textBaseline: 'middle',
                    fontWeight: 'bold',
                },
            },
            {
                type: 'text',
                data: {
                    transform: [
                        {
                            type: 'custom',
                            callback: (d) => {
                                const sum = _round(d.reduce((r, curr) => {
                                    const key = options?.encode?.y || 'value';
                                    r += curr[key];
                                    return r;
                                }, 0), _isNumber(itemOptions?.decimal) && itemOptions?.decimal >= 0
                                    ? itemOptions?.decimal
                                    : 2);
                                return [sum];
                            },
                        },
                    ],
                },
                encode: {
                    text: (d) => d,
                },
                style: {
                    x: '50%',
                    y: '50%',
                    fontSize: 16,
                    dy: 10,
                    textAlign: 'center',
                    textBaseline: 'top',
                    fontWeight: 'bold',
                },
            },
        ];
        const { autoFit, data, children, ...rest } = options || {};
        const result = {};
        if (autoFit) {
            result.autoFit = autoFit;
        }
        if (data) {
            result.data = data;
        }
        if (isArrayNotEmpty(children)) {
            result.children = [...children, ...statics];
        }
        else {
            result.children = [rest, ...statics];
        }
        return result;
    },
};
const loop = {
    title: '图表轮询',
    value: 'loop',
    position: [
        FeaturePosition.pie,
        FeaturePosition.line,
        FeaturePosition.column,
        FeaturePosition.statisticCard,
    ],
    getDefaultProps: () => {
        return {};
    },
    getPrototypeList: (position, config) => {
        return [
            {
                name: 'interval',
                title: '轮询间隔时间（单位秒）',
                display: 'inline',
                setter: 'NumberSetter',
            },
            {
                name: 'needLoading',
                title: '展示loading效果',
                display: 'inline',
                defaultValue: false,
                setter: 'BoolSetter',
            },
        ];
    },
    handleFeature: (config) => {
        const { featureItemConfig, props, sendRequest } = config || {};
        const { _nodeId } = props || {};
        const { active, options } = featureItemConfig || {};
        const { interval, needLoading } = options || {};
        if (active &&
            _nodeId &&
            typeof interval === 'number' &&
            interval >= 2 &&
            componentLoopMap[_nodeId] === undefined) {
            componentLoopMap[_nodeId] = setInterval(() => {
                sendRequest?.({
                    needLoading,
                });
            }, interval * 1000);
        }
    },
};
const labelFilter = {
    title: 'x轴标签过滤',
    value: 'labelFilter',
    position: [FeaturePosition.line, FeaturePosition.column],
    getDefaultProps: () => {
        return {};
    },
    getPrototypeList: (position, config) => {
        return [
            {
                title: '开启过滤(x轴过密时开启)',
                name: 'labelFilter',
                setter: getMixedSetterSnippet({
                    setters: [
                        {
                            componentName: 'BoolSetter',
                            title: '是/否',
                        },
                        getJSExpressionPrototype({
                            type: 'base',
                        }),
                    ],
                }),
            },
        ];
    },
    handleFeature: (config) => {
        const { featureItemConfig, options } = config || {};
        const { options: itemOptions } = featureItemConfig || {};
        const { labelFilter } = itemOptions || {};
        if (labelFilter === true) {
            const firstOptions = getFirstOptions(options);
            if (firstOptions) {
                set(firstOptions, 'axis.x.labelFilter', (item, index, data) => {
                    const dl = data?.length;
                    return index % Math.ceil(dl / 10) === 0;
                });
            }
        }
        return options;
    },
};
export const componentMap = {
    summary,
    loop,
    labelFilter,
};
