import React from 'react';
const { SelectControl } = window?.VisualEngineUtils || {};

import './index.scss';
import { getDataSourceList } from '../../util/util';


class DataSourceSetter extends React.Component {
  // // 组件属性
  // static defaultProps = {
  //   initBind:false,
  // };

  state = {
    dataSourceList: [],
  };

  componentDidMount() {
  }

  // createDataSource = (dataSourceList) =>{
  //   // Engine.Pages.currentPage.ctx.managerMap.dataPool.addItem({description: "",
  //   // dpType: "VALUE",
  //   // id: "",
  //   // initialData: "",
  //   // isGlobal: false,
  //   // name: "dp33",
  //   // protocal: "VALUE"})
  //   const { initBind, prop, selected = {} } = this.props;
  //   const { componentName } = selected
  //   if(initBind && componentName && dataSourceList.length > 0) {
  //     const first = dataSourceList.find(item=>item.value === `${componentName}`);
  //     let index = '';
  //     if(first) {
  //       index = this.generateIndex(dataSourceList,componentName);
  //     }
  //     const componentPrototype = Trunk?.getPrototype(componentName);
  //     const dataSource = Engine?.context?.getManager('dataPool');
  //     const ds = dataSource?.addItem({
  //       description: `${componentPrototype?.options?.title || componentName}${index}的数据源`,
  //       dpType: "VALUE",
  //       id: "",
  //       initialData: "",
  //       isGlobal: false,
  //       name: `${componentName}${index}`,
  //       protocal: "VALUE",
  //     })
  //     if(ds && ds.name) {
  //       this.select(ds.name);
  //     }
  //     return ds.name;
  //   }
  // }

  componentWillMount() {
    const { prop } = this.props;
    // 监听
    this.willDetach = prop.onValueChange(() => this.forceUpdate());
  }

  componentWillUnmount() {
    // 卸载监听
    this.willDetach && this.willDetach();
  }
  //
  // select = (value) => {
  //   const { prop } = this.props;
  //   prop.getNode().setPropValue(prop.getName(), {
  //     type: "JSExpression",
  //     value: `this.dataSource.getDataSource('${value}')`
  //   });
  // };

  // getDsName = () => {
  //   const { prop } = this.props;
  //   const origin = prop.getNode().getPropValue(prop.getName()) || this.props.value;
  //   let value = "";
  //   const variable = origin && (origin.value || origin.variable);
  //   if (origin && variable) {
  //     value = variable.slice(variable.indexOf("'") + 1, variable.lastIndexOf("'"));
  //   }
  //   return value;
  // };
  //
  // // 获取默认的Form/TableQueryForm数据源
  // getFormDefaultDataSource = () => {
  //   const { prop } = this.props;
  //   const selected = prop.getNode();
  //   const { dataSourceList } = this.state;
  //   // const selectedNodeId = selected.id;
  //   const dataSourceManager = Engine.context.getManager("EpochDataSource") as DataSourceManager;
  //
  //   const { dataSourceName /* uuid, componentName */ } = dataSourceManager.getDataSourceInfoByNode(selected);
  //   let defaultFormDataSourceName = null;
  //   const initBind = this.isInitBindDataSource(prop);
  //   if (initBind) {
  //     dataSourceList.forEach((item) => {
  //       if (item.value === dataSourceName) {
  //         defaultFormDataSourceName = item.value;
  //       }
  //     });
  //   }
  //   if (defaultFormDataSourceName) {
  //     this.select(defaultFormDataSourceName);
  //   }
  //   return defaultFormDataSourceName;
  // };
  //
  // isInitBindDataSource(prop: any = {}) {
  //
  //   const selected = prop.getNode();
  //   if (
  //     selected.componentName === "Form" ||
  //     selected.componentName === "TableQueryForm" ||
  //     selected.componentName === "OMSTableQueryForm" ||
  //     selected.componentName === "OMSFormDialog" ||
  //     selected.componentName === "OMSFormEmbed" ||
  //     selected.componentName === "DetailBlock" ||
  //     selected.componentName === "DetailContainer" ||
  //     selected.componentName === 'EpochTableQueryForm' ||
  //     selected.componentName === "OMSDetail"
  //   ) {
  //     return true;
  //   }
  //
  //   const initBind = prop?._config?.setter?.props?.initBind;
  //
  //   if(initBind) {
  //     return true;
  //   }
  //
  //   return false;
  // }

  select = (value) => {
    const { prop } = this.props;
    try {
      prop.getNode().setPropValue(prop.getName(), {
        type: 'variable',
        variable: `state.${value}`,
      });
      // prop.setVariableValue({
      //     type: "variable",
      //     variable: `state.${value}`,
      //    })
      // prop.setHotValue({
      //   type: "variable",
      //   variable: `state.${value}`,
      // })
    } catch (e) {
    }
  };

  getDsName = () => {
    let value = '';
    const { prop } = this.props;
    try {
      // const dsExpr = prop.getNode().getPropValue(prop.getName())
      const dsExpr = prop.getHotValue();
      const variableStr = prop.getVariableValue();
      if (dsExpr && dsExpr.type === 'variable' && dsExpr.variable) {
        value = dsExpr.variable.slice(dsExpr.variable.lastIndexOf('.') + 1);
      } else if (variableStr) {
        value = variableStr.slice(variableStr.lastIndexOf('.') + 1);
      }
    } catch (e) {
      console.log('error');
    }
    return value;
  };

  render() {
    const { prop, selected } = this.props;

    // let dsName = prop.value;
    let dataSourceList = [];
    const dsName = this.getDsName();
    if (!dsName) {
      // dataSourceList = this.getDataSourceList();
      // dsName = this.createDataSource(dataSourceList)
    }
    dataSourceList = getDataSourceList({
      typeList: ['VALUE'],
    });
    if(Array.isArray(dataSourceList)) {
      dataSourceList = dataSourceList.map(item=>{
        const newLabel = `${item.label} (${item.value})`;
        return {
          ...item,
          label: newLabel,
          text: newLabel,
        }
      })
    }
    return (<SelectControl
      readOnly
      options={dataSourceList}
      value={dsName}
      onChange={this.select}
    />);
  }
}

export default DataSourceSetter;
