import { useEffect } from 'react';
import { componentLoopMap } from '../manager/feature';
export default function useStopLoop(options) {
    const { _nodeId } = options || {};
    useEffect(() => {
        return () => {
            if (_nodeId) {
                const loopId = componentLoopMap[_nodeId];
                if (typeof loopId === 'number') {
                    clearInterval(loopId);
                }
            }
        };
    }, []);
}
