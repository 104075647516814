import { COL, GRID, P, SECTION, TILE } from '../util/const';
import { getItemDefaultProps, getItemListByPosition, getItemPrototypeListByPosition, handleI18nLabel, } from '../util/util';
import { componentMap as featureComponentMap } from '../manager/feature';
import CheckboxSetter from '../setter/checkbox-setter';
import { DisplayPosition } from '../types/display-position';
import IdSetter from '../setter/id-setter';
export function getObjectSetterSnippet(config) {
    const { items, initialValue } = config || {};
    const result = {
        componentName: 'ObjectSetter',
        props: {
            config: {
                items,
            },
        },
    };
    if (initialValue) {
        result.initialValue = initialValue;
    }
    return result;
}
export function createHeaderSnippet() {
    return {
        componentName: 'CnLayoutHeader',
        title: '页头',
        props: {},
        children: [createCellSnippet()],
    };
}
export const createCellSnippet = (children) => {
    return {
        componentName: 'CnLayoutCell',
        title: '容器',
        props: {},
        children: children || [],
    };
};
export const createFooterSnippet = () => {
    return {
        componentName: 'CnLayoutFooter',
        title: '页尾',
        props: {},
        children: [createCellSnippet()],
    };
};
export const createSectionSnippet = ({ blockProps } = {}) => {
    return {
        componentName: SECTION,
        title: '瓷片分组',
        props: {},
        children: [createBlockSnippet({ blockProps })],
    };
};
export const createBlockSnippet = ({ blockProps } = {}) => {
    return {
        componentName: TILE,
        title: '瓷片',
        props: {
            ...blockProps,
        },
        children: [createCellSnippet()],
    };
};
export const createGridlSnippet = () => {
    return {
        componentName: GRID,
        title: '网格容器',
        props: {
            rows: 2,
            cols: 2,
        },
        children: [],
    };
};
export const createRowColSnippet = (componentName = COL) => {
    return {
        componentName,
        title: componentName === COL ? '列容器' : '行容器',
        props: {},
        children: [],
    };
};
export const createAsideSnippet = () => {
    return {
        componentName: 'CnLayoutAside',
        title: '侧边栏',
        props: {},
        children: [createCellSnippet(createSectionSnippet())],
    };
};
/**
 * 返回包裹了P标签的schema，会根据dragged的类型设置不同的属性
 * @param {*} dragged 被拖入的组件，是个引擎 node 类型
 * @returns {} 返回值是个对象
 */
export const createPSnippet = () => {
    return {
        componentName: P,
        title: '段落',
        props: {},
        children: [],
    };
};
export function getDataOriginSetterSnippet(config = {}) {
    return {
        name: 'dataOrigin',
        title: '数据来源',
        display: 'block',
        initialValue: 'static',
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: [
                    { title: '静态数据', value: 'static' },
                    { title: '远程请求', value: 'request' },
                ],
            },
        },
        ...config,
    };
}
export function createSectionInitialChildren() {
    return [
        createBlockSnippet({
            blockProps: {
                _layout: {
                    span: 6,
                },
            },
        }),
        createBlockSnippet({
            blockProps: {
                _layout: {
                    span: 6,
                },
            },
        }),
    ];
}
export function createFilterSelectSetters(config, extraConfig) {
    const { mode } = config || {};
    return [
        {
            name: '_bindFilter',
            title: '选择筛选栏',
            setter(prop) {
                const options = [];
                prop?.getNode?.()?.document?.nodesMap?.forEach((item) => {
                    if (item.getPropValue('isCnFilter') ||
                        item.getPropValue('isFilterCondition')) {
                        const prefix = '筛选条件';
                        const id = item.id || '';
                        const title = `${prefix}_${handleI18nLabel(item?.propsData?.title) || ''}`;
                        options.push({
                            title,
                            value: id,
                        });
                    }
                });
                return {
                    componentName: 'CnSelectSetter',
                    props: {
                        mode,
                        options,
                        selectProps: {
                            hasClear: true,
                        },
                    },
                };
            },
            ...extraConfig,
        },
    ];
}
export function createFilterConfigSetter() {
    return {
        name: 'filterConfig',
        title: '过滤条件',
        setter: getObjectSetterSnippet({
            items: [
                ...createFilterSelectSetters({
                    mode: 'multiple',
                }, {
                    title: '绑定筛选条件',
                }),
            ],
        }),
    };
}
export function getChartCommonStyleSetterSnippet(config) {
    const { items = [], heightConfig } = config || {};
    return {
        name: 'chartStyle',
        title: '样式',
        display: 'accordion',
        setter: getObjectSetterSnippet({
            items: [
                {
                    name: 'height',
                    title: '高度',
                    setter: 'NumberSetter',
                    defaultValue: 288,
                    ...heightConfig,
                },
                ...items,
            ],
        }),
    };
}
export function getGroupSetterSnippet(config) {
    const { outerConfig, items } = config || {};
    return {
        type: 'group',
        title: '分组',
        // extraProps: {
        //   display: 'block',
        // },
        display: 'accordion',
        items,
        ...outerConfig,
    };
}
export function getArraySetterSnippet(config) {
    const { initialValue, configure } = config || {};
    return {
        componentName: 'ArraySetter',
        props: {
            mode: 'list',
            itemSetter: {
                componentName: 'ObjectSetter',
                initialValue,
                props: {
                    config: {
                        items: configure,
                    },
                },
            },
        },
    };
}
export function getCnSelectSetterSnippet(config) {
    const { propsConfig, options } = config || {};
    return {
        componentName: 'CnSelectSetter',
        props: {
            options,
            selectProps: {
                hasClear: true,
            },
            ...propsConfig,
        },
    };
}
export function getComponentSlotSetterSnippet(config) {
    const { title, slotInitialValue } = config || {};
    return {
        name: 'componentSlot',
        title: title || '开启区域筛选',
        setter: {
            componentName: 'SlotSetter',
            title: '组件坑位',
            initialValue: slotInitialValue || {
                type: 'JSSlot',
                value: [createDefaultFilter()],
            },
        },
        extraProps: {
            getValue(target, value) {
                if (value && value?.type === 'JSSlot') {
                    return value;
                }
                else if (value === undefined || value === null) {
                    return '';
                }
                else {
                    return {
                        type: 'JSSlot',
                        value: [
                            {
                                componentName: 'CnFilter',
                                props: {
                                    title: '区域的筛选栏',
                                },
                            },
                        ],
                    };
                }
            },
        },
    };
}
export function getChartCommonOptions() {
    return {
        autoFit: true,
        // theme: "classic",
    };
}
export function getPieCharOptions() {
    return {
        ...getChartCommonOptions(),
        type: 'interval',
        coordinate: {
            type: 'theta',
        },
        transform: [
            {
                type: 'stackY',
            },
        ],
        animate: {
            enter: {
                type: 'waveIn',
            },
        },
    };
}
export function getColumnCharOptions() {
    return {
        ...getChartCommonOptions(),
        type: 'interval',
    };
}
export function getLineCharOptions() {
    return {
        ...getChartCommonOptions(),
        type: 'line',
    };
}
export function createDefaultFilter() {
    return {
        componentName: 'CnFilter',
        props: {
            title: '瓷片筛选栏',
            filterStyle: {
                showFolder: false,
                showSelected: false,
                enableConfig: false,
                showBottomLine: false,
                hideButton: true,
                removeEmptyLabel: true,
                size: 'small',
                maxVisibleRow: 999,
            },
            mobileProps: {
                positionFix: false,
            },
            className: 'l2-cn-filter-in-chart',
            config: [
                {
                    label: '',
                    name: 'radio',
                    componentName: 'RadioGroup',
                    options: {
                        extra: {
                            direction: 'hoz',
                            type: 'button',
                        },
                        dataSource: [
                            {
                                label: '选项1',
                                value: 'radio1',
                            },
                            {
                                label: '选项2',
                                value: 'radio2',
                            },
                        ],
                        dataOrigin: 'static',
                    },
                },
            ],
        },
    };
}
export function createFeatureSetters(config = {}) {
    const { position } = config;
    const prototypeList = getItemPrototypeListByPosition({
        position,
        primaryKey: 'name',
        componentMap: featureComponentMap,
    });
    const configure = [];
    if (prototypeList?.length > 0) {
        configure.push({
            name: 'options',
            display: 'plain',
            title: '请完善配置',
            setter: {
                componentName: 'ObjectSetter',
                props: {
                    config: {
                        items: [...prototypeList],
                    },
                },
            },
        });
    }
    else {
        return [];
    }
    return {
        componentName: 'ArraySetter',
        props: {
            mode: 'list',
            itemSetter: {
                componentName: 'ObjectSetter',
                initialValue: { active: true },
                props: {
                    config: {
                        items: [
                            {
                                name: 'name',
                                display: 'inline',
                                title: '功能名称',
                                isRequired: true,
                                setter: {
                                    componentName: 'SelectSetter',
                                    props: (field) => {
                                        const parentComponentConfig = field?.parent?.parent?.parent?.getValue?.();
                                        const componentName = parentComponentConfig?.componentName;
                                        const validatorList = getItemListByPosition({
                                            position,
                                            componentMap: featureComponentMap,
                                            extraQueryParams: {
                                                showInFilterComponentList: componentName,
                                            },
                                        });
                                        return {
                                            options: validatorList,
                                        };
                                    },
                                },
                                extraProps: {
                                    setValue(target, value) {
                                        const defaultProps = getItemDefaultProps(featureComponentMap, position, value);
                                        if (defaultProps && Object.keys(defaultProps)?.length > 0) {
                                            target?.parent?.setPropValue?.('options', defaultProps);
                                        }
                                        else {
                                            target?.parent?.setPropValue?.('options', {});
                                        }
                                    },
                                },
                            },
                            {
                                name: 'active',
                                display: 'inline',
                                isRequired: true,
                                // className: 'cn-array-setter-validator-active',
                                title: '启用',
                                // setter: <CheckboxSetter />,
                                setter: {
                                    componentName: 'MixedSetter',
                                    props: {
                                        setters: [
                                            {
                                                componentName: <CheckboxSetter />,
                                                title: '启用/禁用',
                                            },
                                        ],
                                    },
                                },
                            },
                            ...configure,
                        ],
                    },
                },
            },
        },
    };
}
export function getJSXTemplate(config) {
    if (config) {
        const { position } = config || {};
        const temp = {
            componentName: 'CnJSXSetter',
            props: {
                usePopup: true,
                enableFullscreen: true,
                theme: 'vs',
            },
            title: 'React JSX代码',
        };
        if ([DisplayPosition.statisticCard_titleTip].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: 'function content(item, state) { \n  \n}',
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(item, state) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        return temp;
    }
}
export function getNodeIdSetterSnippet() {
    return {
        name: '_nodeId',
        title: '节点 ID',
        display: 'inline',
        initialValue() {
            return this.getNode().id;
        },
        setter: <IdSetter />,
    };
}
export function getComponentTitle(config) {
    return {
        name: 'title',
        title: '组件名称',
        display: 'inline',
        setter: 'StringSetter',
        ...config,
    };
}
