import React from 'react';
import CnStatisticCardView from './statisticcard-view';
class CnStatisticCard extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    reRender = () => {
        this.ref?.current?.reRender?.();
    };
    render() {
        return <CnStatisticCardView ref={this.ref} {...this.props}/>;
    }
}
CnStatisticCard.displayName = 'CnStatisticCard';
export default CnStatisticCard;
export { CnStatisticCard };
