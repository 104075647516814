import React from 'react';
export function StatisticSetter(props) {
    const { draggingField, changeRequestConfigValue } = props || {};
    const getRequestConfigValue = (key) => {
        const { value } = props;
        const { requestConfig } = value || {};
        return requestConfig?.[key];
    };
    const fieldList = getRequestConfigValue('fieldList') || [];
    return (<div className="dw-item dw-left">
      <div className="dw-title">指标</div>
      <div onDragOver={(e) => {
            e.preventDefault();
        }} onDrop={(e) => {
            e.stopPropagation();
            if (draggingField) {
                changeRequestConfigValue?.({
                    fieldList: [
                        ...fieldList,
                        {
                            fieldName: draggingField,
                        },
                    ],
                });
            }
        }} className="dw-content">
        {fieldList?.map((item) => {
            // return <CnMenuButton label={item}/>
            return <div>{item?.fieldName}</div>;
        })}
      </div>
    </div>);
}
export function getMixedSetterSnippet(config) {
    const { setters } = config || {};
    return {
        componentName: 'MixedSetter',
        props: {
            setters,
        },
    };
}
