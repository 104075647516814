import React, { useState } from 'react';
import { CnBox } from '@alife/cn-ui';
import isPlainObject from 'lodash/isPlainObject';
import { CnChart as UICnChart } from '@/components/cn-chart';
import { CnTile } from '@/components/cn-tile';
import { makeRequest } from 'lowcode-common/util/request';
import { calculateAdvancedConfig, calculateFeature, getChartCommonStyle, isArrayNotEmpty, isDesignMode, makeChartRequestConfig, getLowCodeCoreUtil, executeFunction, } from 'lowcode-common/util/util';
import { RequestError } from '@/lowcode/cn-column-chart/components/request-error';
import { EmptyData } from '@/lowcode/cn-column-chart/components/empty-data';
import { Loading } from '@/lowcode/cn-column-chart/components/loading';
import useFilterSearchListener from 'lowcode-common/hooks/useFilterSearchListener';
import { getChartCommonOptions } from 'lowcode-common/manager/setter-snippet';
import { FeaturePosition } from 'lowcode-common/types/feature-position';
const CnDynamicChart = (props) => {
    const { dataFrom, _context, filterConfig, tileConfig } = props || {};
    const { requestConfig } = dataFrom || {};
    const { serviceType, guid } = requestConfig || {};
    const [remoteOptions, setRemoteOptions] = useState([]);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const isDesign = isDesignMode(props);
    const { _bindFilter } = filterConfig || {};
    const { isOpenTile, extra } = tileConfig ?? {};
    const sendRequest = () => {
        if (requestConfig && requestConfig.serviceType) {
            const realRequestConfig = makeChartRequestConfig({
                requestConfig,
                _context,
                filterConfig,
                isDesign,
            });
            setLoading(true);
            makeRequest?.(realRequestConfig).then((res) => {
                const { data } = res || {};
                const { chartList = [] } = data || {};
                setRemoteOptions(chartList);
                setLoading(false);
                setError(undefined);
            }, () => {
                setLoading(false);
                setError('请求失败');
            });
        }
    };
    useFilterSearchListener({
        _bindFilter,
        sendRequest,
        _context,
    });
    const finalOptions = calculateAdvancedConfig({
        props,
        options: calculateFeature({
            props,
            options: remoteOptions,
            position: FeaturePosition.dynamic,
        }),
        state: _context?.state,
    });
    const renderDynamicChart = () => {
        return finalOptions?.map((item, index) => {
            const title = executeFunction(tileConfig?.title, item, _context?.state);
            const titleTip = executeFunction(tileConfig?.titleTip, item, _context?.state);
            const tileStyleProps = getTileStyle(tileConfig?.tileStyle, item, _context?.state);
            const extraDom = getTileButtons(extra, item, _context);
            return isOpenTile ? (<CnTile {...tileStyleProps} extra={extraDom} title={title} titleTip={titleTip}>
          <UICnChart key={index} {...getChartCommonStyle(props)} {...getChartCommonOptions()} {...item}/>
        </CnTile>) : (<UICnChart key={index} {...getChartCommonStyle(props)} {...getChartCommonOptions()} {...item}/>);
        });
    };
    if (isDesign && loading === undefined) {
        return (<UICnChart {...getChartCommonStyle(props)} autoFit encode={{
                x: 'year',
                y: 'amount',
            }} data={[
                { year: '2022', amount: 111 },
                { year: '2023', amount: 222 },
            ]}/>);
    }
    if (error) {
        return <RequestError error={error}/>;
    }
    return (<Loading visible={loading}>
      {isArrayNotEmpty(finalOptions) ? (renderDynamicChart()) : (<EmptyData emptyImg={'https://img.alicdn.com/imgextra/i1/O1CN01h2uPcg1Untx4htgLQ_!!6000000002563-55-tps-500-260.svg'}/>)}
    </Loading>);
};
function getTileButtons(extra, record, _context) {
    let extraDom = getLowCodeCoreUtil('util.makeButtons')?.({
        buttons: extra?.map?.((item) => {
            let realChildren = item?.children;
            if (typeof item?.children === 'function') {
                realChildren = executeFunction(item.children, record, _context?.state);
            }
            return {
                ...item,
                size: 'small',
                position: getLowCodeCoreUtil('enumMap.ButtonPosition.tileRightButton'),
                children: realChildren,
            };
        }),
        _context,
        state: _context?.state,
        recordDataSource: record,
        formInstance: {
            values: record,
        },
    });
    if (isArrayNotEmpty(extraDom)) {
        extraDom = (<CnBox spacing={8} direction="row">
        {extraDom}
      </CnBox>);
    }
    return extraDom;
}
function getTileStyle(tileStyle, record, state) {
    const { backgroundColor, type, customStyle, collapsible } = tileStyle || {};
    const newProps = {
        collapsible,
        style: {
            backgroundColor,
        },
        bodyStyle: {},
    };
    const bodyStyle = executeFunction(customStyle, record, state);
    if (isPlainObject(bodyStyle)) {
        newProps.bodyStyle = bodyStyle;
    }
    if (type === 'noBorder') {
        newProps.style.backgroundColor = 'transparent';
        newProps.style.padding = 0;
        newProps.style.boxShadow = 'none';
    }
    return newProps;
}
CnDynamicChart.displayName = 'CnDynamicChart';
export default CnDynamicChart;
export { CnDynamicChart };
