import React from 'react';

const { Checkbox } = window.CNUI || {}

export default class CheckboxSetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, onChange } = this.props;
    return (
      <Checkbox
        checked={value}
        onChange={(v) => {
          onChange?.(v);
        }}
      />
    );
  }
}
