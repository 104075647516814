import React, { useState } from 'react';
import { CnChart } from '@/components/cn-chart';
import { makeRequest } from 'lowcode-common/util/request';
import { RequestError } from '@/lowcode/cn-column-chart/components/request-error';
import { calculateAdvancedConfig, calculateFeature, getChartCommonStyle, getRealData, isDesignMode, judgeDataEmpty, makeChartRequestConfig, setAliasName, } from 'lowcode-common/util/util';
import { EmptyData } from '@/lowcode/cn-column-chart/components/empty-data';
import useFilterSearchListener from 'lowcode-common/hooks/useFilterSearchListener';
import { Loading } from '@/lowcode/cn-column-chart/components/loading';
import { getLineCharOptions } from 'lowcode-common/manager/setter-snippet';
import { FeaturePosition } from 'lowcode-common/types/feature-position';
import useStopLoop from 'lowcode-common/hooks/useStopLoop';
import set from 'lodash/set';
const CnLineChart = (props) => {
    const { dataFrom, _context, filterConfig, chartStyle } = props || {};
    const { requestConfig } = dataFrom || {};
    const { serviceType, guid, xField, yField, series } = requestConfig || {};
    const { shape, area } = chartStyle || {};
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const realXField = xField?.[0]?.fieldName;
    const realYField = yField?.[0]?.fieldName;
    const xAliasName = xField?.[0]?.aliasName;
    const yAliasName = yField?.[0]?.aliasName;
    const seriesField = series?.[0]?.fieldName || undefined;
    const isDesign = isDesignMode(props);
    const { _bindFilter } = filterConfig || {};
    const sendRequest = (config) => {
        if (requestConfig && requestConfig.serviceType) {
            const realRequestConfig = makeChartRequestConfig({
                requestConfig,
                _context,
                filterConfig,
                isDesign,
            });
            const { needLoading } = config || {};
            if (needLoading !== false) {
                setLoading(true);
            }
            makeRequest?.(realRequestConfig).then((res) => {
                const data = getRealData(realRequestConfig, res, _context);
                const { value = [] } = data || {};
                setListData(value);
                setLoading(false);
                setError(undefined);
            }, () => {
                setLoading(false);
                setError('请求失败');
            });
        }
    };
    useFilterSearchListener({
        _bindFilter,
        sendRequest,
        _context,
    });
    useStopLoop({
        _nodeId: props?._nodeId,
    });
    const realOptions = {
        ...getLineCharOptions(),
        encode: {
            x: realXField,
            y: realYField,
            series: seriesField,
            color: seriesField,
        },
        data: listData,
        style: {
            lineWidth: 2,
        },
        legend: {
            color: {
                title: false,
            },
        },
    };
    if (shape === 'smooth') {
        realOptions.encode.shape = shape;
    }
    if (area) {
        realOptions.type = 'area';
        set(realOptions, 'style.fillOpacity', 0.5);
    }
    setAliasName('x', xAliasName, realOptions);
    setAliasName('y', yAliasName, realOptions);
    const finalOptions = calculateAdvancedConfig({
        props,
        options: calculateFeature({
            props,
            options: realOptions,
            position: FeaturePosition.line,
            sendRequest,
        }),
        state: _context?.state,
    });
    if (isDesign && loading === undefined) {
        return (<CnChart {...getChartCommonStyle(props)} {...finalOptions} encode={{
                x: 'year',
                y: 'amount',
            }} data={[
                { year: '2022', amount: 111 },
                { year: '2023', amount: 222 },
            ]}/>);
    }
    if (error) {
        return <RequestError error={error}/>;
    }
    return (<Loading visible={loading}>
      {judgeDataEmpty(finalOptions) ? (<EmptyData emptyImg={'https://img.alicdn.com/imgextra/i1/O1CN01h2uPcg1Untx4htgLQ_!!6000000002563-55-tps-500-260.svg'}/>) : (<CnChart {...getChartCommonStyle(props)} {...finalOptions}/>)}
    </Loading>);
    // return <EmptyData
    //   emptyImg={'https://img.alicdn.com/imgextra/i1/O1CN01h2uPcg1Untx4htgLQ_!!6000000002563-55-tps-500-260.svg'}
    // />
};
CnLineChart.displayName = 'CnLineChart';
export default CnLineChart;
export { CnLineChart };
