import { calculateTextExprValue, getRealRequestUrl, getRealResponse, handleResultProcessFunc2, handleSuccessMessage, transRequestConfigToRemote, } from './util';
import { request as requestInstance } from 'cn-request';
import isPlainObject from 'lodash/isPlainObject';
import { AfterRequestSuccess } from './const';
import { CnMessage, CnDialog } from '@alife/cn-ui';
export function makeRequest(config) {
    let { needSuccessToast = true } = config;
    const { handleParams, buttonConfig, record, state, recordDataSource, urlParamsDataSource, onCancel, extraParamList, isDesign, position = '', noNeedHandleResult, _context, getExtraParam, handleParamsBeforeSend, } = config;
    return new Promise((resolve, reject) => {
        if (buttonConfig) {
            const { options = {} } = buttonConfig;
            const { requestConfig, needConfirm, confirmInfo = {}, afterRequest } = options;
            const sendRequest = () => {
                const realUrl = getRealRequestUrl({
                    requestConfig,
                    state,
                    extraParam: getExtraParam?.(),
                });
                if (realUrl) {
                    requestInstance(transRequestConfigToRemote({
                        requestConfig,
                        handleParams,
                        state,
                        urlParamsDataSource,
                        recordDataSource,
                        isDesign,
                        extraParamList,
                        getExtraParam,
                        handleParamsBeforeSend,
                    }))
                        .then((res) => {
                        let result = getRealResponse(res);
                        if (!noNeedHandleResult) {
                            result = handleResultProcessFunc2(res, requestConfig, { state });
                        }
                        const { success, errorCode, errorMsg } = result;
                        if (success) {
                            if (afterRequest?.optType) {
                                const pos = position + AfterRequestSuccess;
                            }
                            resolve(result);
                            if (needSuccessToast) {
                                handleSuccessMessage(result);
                            }
                        }
                        else {
                            reject(result);
                            CnMessage.error(errorMsg || errorCode || `${requestConfig?.url} 请求异常`);
                        }
                    })
                        .catch((err) => {
                        reject(err);
                        // const {errorCode, errorMsg } = err || {}
                        // CnMessage.error(errorMsg || errorCode || `${requestConfig?.url} 请求异常`);
                    });
                }
                else {
                    reject();
                }
            };
            if (needConfirm) {
                const title = calculateTextExprValue(confirmInfo.title, {
                    urlParamsDataSource,
                    recordDataSource,
                    state,
                    extraParamList,
                });
                const content = calculateTextExprValue(confirmInfo.content, {
                    urlParamsDataSource,
                    recordDataSource,
                    state,
                    extraParamList,
                });
                CnDialog.confirm({
                    title: title || '通知',
                    content: content || '',
                    messageProps: {
                        className: 'l2-dialog-message-content',
                    },
                    onOk: () => {
                        sendRequest();
                    },
                    onCancel: () => {
                        onCancel && onCancel();
                    },
                    onClose: () => {
                        onCancel && onCancel();
                    },
                    // cancelProps: {
                    //   // children: '关闭弹窗',
                    // },
                });
            }
            else {
                sendRequest();
            }
        }
    });
}
export function handleResultProcessFunc(requestConfig) {
    if (requestConfig) {
        const { resultProcessFunc, serviceType, mockData } = requestConfig;
        if (typeof resultProcessFunc === 'function') {
            return (res, state) => {
                let newRes = getRealResponse(res);
                try {
                    const temp = resultProcessFunc(newRes, state);
                    if (temp && temp.data && temp.success !== undefined) {
                        newRes = temp.data;
                    }
                    else {
                        newRes = temp;
                    }
                }
                catch (e) {
                    console.error('请求结果回调执行失败', e);
                }
                return newRes;
            };
        }
        else if (serviceType === 'mock' && isPlainObject(mockData)) {
            return () => {
                return mockData?.data;
            };
        }
        else {
            return (res) => {
                if (res?.data) {
                    return res.data;
                }
                return res;
            };
        }
    }
}
