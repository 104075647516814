import CnTile from './cn-tile';
import CnLayoutBundle from '@cainiaofe/cn-ui-layout/es/lowcode/index';
import CnStatisticCard from './cn-statistic-card';
import CnColumnChart from './cn-column-chart';
import CnLineChart from './cn-line-chart';
import CnPieChart from './cn-pie-chart';
import CnChart from './cn-chart';
import CnProgress from './cn-progress';
import CnDynamicChart from './cn-dynamic-chart';

// 通过arr控制输出的顺序
let bundle = [
  CnTile,
  CnStatisticCard,
  CnColumnChart,
  CnLineChart,
  CnPieChart,
  CnChart,
  CnProgress,
  CnDynamicChart,
];

const CnLayoutPage = window?.CN_UI_LOWCODE?.default?.find?.(
  (item) => item?.displayName === 'CnLayoutPage',
);
if (!CnLayoutPage) {
  bundle = [...bundle, ...CnLayoutBundle];
}

window['cn-ui-charts'] = bundle;
export default bundle;
