import React, { useEffect, useRef, forwardRef } from 'react';
import classnames from 'classnames';
import { Chart } from '@antv/g2';
import './index.scss';
const clsPrefix = 'cn-chart';
class ChartProps extends Chart {
}
/**
 * CnChart 组件
 * @param {*} props
 * @returns
 */
const CnChart = forwardRef((props, ref) => {
    const { width, height, className, containerStyle, options, ...rest } = props;
    const { theme = 'classic', autoFit, data } = rest;
    const container = useRef(null);
    const chartRef = useRef(null);
    useEffect(() => {
        if (!container.current) {
            return;
        }
        if (!chartRef.current) {
            const chart = new Chart({
                container: container.current,
                theme,
                autoFit,
                width,
                height,
            });
            chartRef.current = chart;
            if (ref) {
                ref.current = chart;
            }
            chart
                .axis('x', {
                line: true,
                style: {
                    lineLineWidth: 1,
                    lineFill: '#BFBFBF',
                    labelFill: '#666',
                    labelFontWeight: '400',
                },
            })
                .axis('y', {
                line: false,
                tick: false,
                grid: true,
                style: {
                    gridLineWidth: 1,
                    gridFill: '#E7E8ED',
                    labelFill: '#666',
                    labelFontWeight: '400',
                },
            })
                .legend('color', {
                style: {
                    itemLabelFill: '#666',
                    itemLabelFontWeight: '400',
                },
            });
        }
        chartRef.current.options({ ...rest, ...options });
        setTimeout(() => {
            chartRef?.current?.render();
        }, 0);
        if (ResizeObserver) {
            let iter;
            const ro = new ResizeObserver((entries) => {
                if (entries?.length) {
                    const rectWidth = entries[0]?.contentRect?.width;
                    const rectHeight = entries[0]?.contentRect?.height;
                    if (rectWidth && rectHeight) {
                        if (iter) {
                            clearTimeout(iter);
                        }
                        iter = setTimeout(() => {
                            chartRef.current?.changeSize(rectWidth, rectHeight);
                        }, 800);
                    }
                }
            });
            ro.observe(container.current);
        }
        // chartRef.current.changeData(data);
    }, [data, options]);
    return (<div ref={container} className={classnames(clsPrefix, className)} style={containerStyle}/>);
});
CnChart.displayName = 'CnChart';
export { CnChart, ChartProps };
