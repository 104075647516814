import React from 'react';
import { CnTile as BaseCnTile } from 'common/components/cn-tile/index';
import { CnIcon, CnBalloon } from '@alife/cn-ui';
import './index.scss';
const titleClsPrefix = 'cn-tile-title';
/**
 * CnTile 组件
 * @param {*} props
 * @returns
 */
function CnTile(props) {
    const { titleTip } = props;
    const renderCollapsibleIcon = (collapsed) => {
        return <CnIcon type={!collapsed ? 'triangle-down-fill' : 'triangle-right-fill'}/>;
    };
    const renderTitleTip = () => {
        return (<CnBalloon closable={false} trigger={<CnIcon className={`${titleClsPrefix}-tip-icon`} type="help-color"/>} triggerType="hover" align="t">
        {titleTip}
      </CnBalloon>);
    };
    return (<BaseCnTile {...props} renderCollapsibleIcon={renderCollapsibleIcon} renderTitleTip={renderTitleTip}/>);
}
export { CnTile };
