import React from 'react';
import { CnBox } from '@alife/cn-ui';
import { CnLayoutBlock } from '@/components/cn-layout/index';
import { CnTile as UICnTile } from '@/components/cn-tile/index';
import { executeFunction, getLowCodeCoreUtil, getSlotDom, isArrayNotEmpty, isDesignMode, } from 'lowcode-common/util/util';
import isPlainObject from 'lodash/isPlainObject';
const CnTile = (props) => {
    const { children, span, _context, _filter, tileStyle, extra } = props;
    const { backgroundColor, type, customStyle, ...rest } = tileStyle || {};
    const { componentSlot, slotPosition } = _filter || {};
    const isDesign = isDesignMode(props);
    const state = _context?.state;
    let extraDom = getLowCodeCoreUtil('util.makeButtons')?.({
        buttons: extra?.map((item) => {
            let realChildren = item?.children;
            if (typeof item?.children === 'function') {
                realChildren = executeFunction(item.children, {}, state);
            }
            return {
                ...item,
                size: 'small',
                position: getLowCodeCoreUtil('enumMap.ButtonPosition.tileRightButton'),
                children: realChildren,
            };
        }),
        _context,
        state,
        recordDataSource: {},
    });
    const tileProps = {};
    if (isArrayNotEmpty(extraDom)) {
        extraDom = (<CnBox spacing={8} direction="row">
        {extraDom}
      </CnBox>);
    }
    const realStyle = {
        backgroundColor,
    };
    const bodyStyle = executeFunction(customStyle);
    if (isPlainObject(bodyStyle)) {
        tileProps.bodyStyle = bodyStyle;
    }
    if (type === 'noBorder') {
        realStyle.backgroundColor = 'transparent';
        realStyle.padding = 0;
        realStyle.boxShadow = 'none';
    }
    let filterSlot = null;
    if (slotPosition === 'right') {
        const tempFilter = getSlotDom({
            componentSlot,
            isDesign,
            componentProps: {
                className: 'l2-cn-filter-in-chart l2-cn-filter-in-tile-right-slot',
            },
        });
        extraDom = (<>
        {tempFilter}
        {extraDom}
      </>);
    }
    else {
        filterSlot = getSlotDom({
            componentSlot,
            isDesign,
        });
    }
    return (<CnLayoutBlock className={'cn-tile-block-wrap'} span={span}>
      <UICnTile {...tileProps} style={realStyle} {...rest} extra={extraDom}>
        {slotPosition !== 'right' && filterSlot}
        {children}
      </UICnTile>
    </CnLayoutBlock>);
};
CnTile.typeMark = 'Block';
CnTile.displayName = 'CnTile';
export default CnTile;
export { CnTile };
