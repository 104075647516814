import React, { isValidElement } from 'react';
import classnames from 'classnames';
import { initArray } from '../../utils';
import './index.scss';
import { CnChart } from '../cn-chart';
export const clsPrefix = 'cn-statistic-card';
/**
 * CnStatisticCard 组件
 * @param {*} props
 * @returns
 */
export function CnStatisticCard(props) {
    const { className, style, icon, statistic, period, compare, trend, progress, action, noPadding = false, noBorder = false, bgColor, hover, onClick, active = false, shape = 'normal', beforeTrendRender, trendPosition = 'bottom', subIndicators, horizontalConfig = {}, size = 'medium', renderStatisticTip, renderProgressBar, CnBox, CnIcon, } = props;
    const renderStatisticVal = (val) => {
        if (val.formatter && typeof val.formatter === 'function') {
            return val.formatter(val.value);
        }
        return val.value?.toLocaleString();
    };
    const finallyStyle = style
        ? { ...style, backgroundColor: bgColor }
        : { backgroundColor: bgColor };
    const renderAction = (config) => {
        if (!config) {
            return null;
        }
        if (isValidElement(config)) {
            return <div className={`${clsPrefix}-statistic-action`}>{config}</div>;
        }
        const actionData = config;
        return (<div className={`${clsPrefix}-statistic-action`} onClick={actionData?.onClick}>
        {actionData?.text}
      </div>);
    };
    /* const renderStatisticTip = () => {
      if (!statistic?.tip) {
        return null;
      }
      return <CnPopoverPro statistic={statistic} />;
    }; */
    const renderIcon = () => {
        if (!icon) {
            return null;
        }
        return (<div className={`${clsPrefix}-icon`}>
        <img src={icon.src} className={`${clsPrefix}-icon-img`}/>
      </div>);
    };
    const renderCompareIcon = (item) => {
        const colorFun = compare?.color;
        if (item.arrowDirection) {
            if (item.arrowDirection > 0) {
                return (<CnIcon type="triangle-up-fill" className={`${clsPrefix}-compare-item-icon`} color={colorFun && typeof colorFun === 'function'
                        ? colorFun(item)
                        : '#EA6173'}/>);
            }
            if (item.arrowDirection < 0) {
                return (<CnIcon type="triangle-down-fill" className={`${clsPrefix}-compare-item-icon`} color={colorFun && typeof colorFun === 'function'
                        ? colorFun(item)
                        : '#0ACEAC'}/>);
            }
        }
        return null;
    };
    const renderTrend = (trendData) => {
        if (!trendData?.items?.length) {
            return null;
        }
        const { autoDataDomainFit = true, height = 34 } = trendData;
        let domain;
        if (autoDataDomainFit) {
            const maxY = trendData.items.reduce((accumulator, current) => {
                if (current.y > accumulator.y) {
                    return current;
                }
                return accumulator;
            }).y;
            const minY = trendData.items.reduce((accumulator, current) => {
                if (current.y < accumulator.y) {
                    return current;
                }
                return accumulator;
            }).y;
            domain = [minY, maxY];
        }
        const defautMiniOptions = {
            theme: 'classic',
            padding: 0,
            autoFit: true,
            data: trendData.items,
            options: {
                scale: {
                    y: {
                        domain,
                    },
                },
                insetTop: 10,
                children: [
                    {
                        type: 'area',
                        encode: {
                            x: 'x',
                            y: 'y',
                            shape: 'smooth',
                        },
                        style: {
                            fill: 'linear-gradient(90deg, rgba(40,76,191,0.8) 0%, rgba(40,76,191,0.18) 100%)',
                            fillOpacity: 0.2,
                        },
                        axis: false,
                        animate: {
                            enter: { type: 'fadeIn' },
                        },
                        interaction: {
                            tooltip: trendData.tooltip
                                ? trendData.tooltip
                                : {
                                // @ts-ignore
                                // render: (e, { items }) => items[0].value,
                                },
                        },
                    },
                    {
                        type: 'line',
                        axis: false,
                        encode: {
                            x: 'x',
                            y: 'y',
                            shape: 'smooth',
                        },
                        style: {
                            stroke: 'rgba(40,76,191, 1)',
                            strokeWidth: 1,
                        },
                        tooltip: false,
                    },
                ],
            },
        };
        const options = beforeTrendRender
            ? beforeTrendRender(defautMiniOptions)
            : defautMiniOptions;
        return (<div className={classnames(`${clsPrefix}-trend`, {
                [`${clsPrefix}-trend-right`]: trendPosition === 'right',
            })} onClick={(e) => {
                trendData.onClick && trendData.onClick(e);
            }}>
        {trendData?.title ? (<div className={`${clsPrefix}-trend-title`}>{trendData.title}</div>) : null}
        <div className={`${clsPrefix}-trend-wrap`} 
        // @ts-ignore
        style={{ height: options?.height || height }}>
          {options && <CnChart {...options}/>}
        </div>
      </div>);
    };
    const renderProgress = (progressConfig) => {
        if (!progressConfig) {
            return null;
        }
        return (<div className={`${clsPrefix}-progress`}>
        {progressConfig.title ? (<div className={`${clsPrefix}-progress-title`}>
            <div className={`${clsPrefix}-progress-text`}>
              {progressConfig.title}
            </div>
            <div className={`${clsPrefix}-progress-value`}>{`${progressConfig.percent}%`}</div>
          </div>) : null}
        {renderProgressBar(progressConfig)}
      </div>);
    };
    const renderMultipleIndicator = () => {
        const length = subIndicators && subIndicators.length;
        if (!length) {
            return null;
        }
        const finallyConfig = {
            columnGap: 20,
            rowGap: 8,
            columns: 4,
            ...horizontalConfig,
        };
        const { columnGap, rowGap, columns } = finallyConfig;
        const rowCount = Math.ceil(length / columns);
        console.log('initArray', initArray);
        const rows = initArray(rowCount);
        const columnsArray = initArray(columns);
        if (rows.length === 1) {
            return (<div className={classnames(`${clsPrefix}-sub`)}>
          <CnBox spacing={columnGap} direction="row" justify="space-between">
            {columnsArray.map((idx) => {
                    const item = subIndicators[idx];
                    if (!item) {
                        return <div className={`${clsPrefix}-sub-item`} key={idx}/>;
                    }
                    return (<div className={`${clsPrefix}-sub-item`} key={idx}>
                  <div className={`${clsPrefix}-sub-header`}>
                    <div className={`${clsPrefix}-sub-title`}>
                      {item?.title}
                    </div>
                  </div>

                  <div className={`${clsPrefix}-sub-body`}>
                    <span className={`${clsPrefix}-sub-body-value`} style={{ color: item?.valueColor }}>
                      {renderStatisticVal(item)}
                    </span>
                  </div>
                </div>);
                })}
          </CnBox>
        </div>);
        }
        return (<div className={classnames(`${clsPrefix}-sub`)}>
        <CnBox spacing={rowGap}>
          {rows.map((index) => {
                return (<CnBox spacing={columnGap} direction="row" key={index}>
                {subIndicators
                        .filter((item, idx) => idx >= index * columns && idx < index * columns + columns)
                        .map((item, ix) => {
                        return (<div className={`${clsPrefix}-sub-item`} key={ix}>
                        <div className={`${clsPrefix}-sub-header`}>
                          <div className={`${clsPrefix}-sub-title`}>
                            {item.title}
                          </div>
                        </div>

                        <div className={`${clsPrefix}-sub-body`}>
                          <span className={`${clsPrefix}-sub-body-value`} style={{ color: item?.valueColor }}>
                            {renderStatisticVal(item)}
                          </span>
                        </div>
                      </div>);
                    })}
              </CnBox>);
            })}
        </CnBox>
      </div>);
    };
    const renderCompare = () => {
        if (!compare) {
            return null;
        }
        if (!compare.items?.length) {
            return null;
        }
        const { items } = compare;
        return (<div className={`${clsPrefix}-compare`}>
        {items.map((item, index) => {
                return (<div className={`${clsPrefix}-compare-item`} key={index}>
              <span className={`${clsPrefix}-compare-item-key`}>
                {item.text}
              </span>
              <span className={`${clsPrefix}-compare-item-value`}>
                {renderCompareIcon(item)}
                {item.formatter ? item.formatter(item.value) : item.value}
              </span>
            </div>);
            })}
      </div>);
    };
    const renderPeriod = () => {
        if (!period) {
            return null;
        }
        if (!period.items?.length) {
            return null;
        }
        const { items } = period;
        return (<div className={`${clsPrefix}-period`}>
        {items.map((item, index) => {
                return (<div className={`${clsPrefix}-period-item`} key={index}>
              <span className={`${clsPrefix}-period-item-key`}>
                {item.text}
              </span>
              <span className={`${clsPrefix}-period-item-value`}>
                {item.value}
              </span>
            </div>);
            })}
      </div>);
    };
    if (shape === 'multipleIndicator') {
        const length = subIndicators && subIndicators.length;
        if (!length) {
            return <div className={classnames(`${clsPrefix}-sub`)}>暂无数据</div>;
        }
        return (<div className={classnames(clsPrefix, className, {
                padding: !noPadding,
                border: !noBorder,
                hover: !!hover,
                checked: active,
                'cn-statistic-card-small': size === 'small',
                'cn-statistic-card-medium': size === 'medium',
            })} style={finallyStyle} onClick={onClick}>
        <div className={classnames(`${clsPrefix}-content`)}>
          {length ? renderMultipleIndicator() : null}
        </div>
      </div>);
    }
    return (<div className={classnames(clsPrefix, className, {
            padding: !noPadding,
            border: !noBorder,
            hover,
            checked: active,
            'cn-statistic-card-small': shape === 'statistic' || size === 'small',
            'cn-statistic-card-medium': shape !== 'statistic' && size === 'medium',
        })} style={finallyStyle} onClick={onClick}>
      {renderIcon()}
      <div className={classnames(`${clsPrefix}-content`, {
            [`${clsPrefix}-content-has-icon`]: !!icon,
            [`${clsPrefix}-content-horizontal`]: trendPosition === 'right',
        })}>
        <div className={`${clsPrefix}-content-main`}>
          {statistic && (<div className={`${clsPrefix}-statistic`}>
              <div className={`${clsPrefix}-statistic-header`}>
                <div className={`${clsPrefix}-statistic-title`}>
                  {statistic.status ? (<div className={classnames({
                    status: true,
                    [statistic.status]: true,
                })}/>) : null}
                  {statistic.title}
                  {renderStatisticTip(statistic)}
                  {statistic.updateTime ? (<div className={`${clsPrefix}-statistic-time`}>
                      {statistic.updateTime}
                    </div>) : null}
                </div>
                {renderAction(action)}
              </div>

              <div className={`${clsPrefix}-statistic-body`}>
                {statistic.prefix ? (<span className={`${clsPrefix}-statistic-body-prefix`}>
                    {statistic.prefix}
                  </span>) : null}
                <span className={`${clsPrefix}-statistic-body-value`} style={{ color: statistic?.valueColor }}>
                  {renderStatisticVal(statistic)}
                </span>
                {statistic.suffix ? (<span className={`${clsPrefix}-statistic-body-suffix`}>
                    {statistic.suffix}
                  </span>) : null}
              </div>
            </div>)}
          {renderMultipleIndicator()}
          {renderPeriod()}
          {renderCompare()}
          {renderProgress(progress)}
        </div>
        {renderTrend(trend)}
      </div>
    </div>);
}
