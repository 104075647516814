export function RequestError(props) {
    const { error } = props || {};
    return <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
        }}>
    {error || '请求失败'}
  </div>;
}
