import React, { useState } from 'react';
import { CnChart } from '@/components/cn-chart';
import { makeRequest } from 'lowcode-common/util/request';
import { calculateAdvancedConfig, calculateFeature, calculateLabels, getChartCommonStyle, getRealData, isArrayNotEmpty, isDesignMode, isEmptyButNotZero, judgeDataEmpty, makeChartRequestConfig, } from 'lowcode-common/util/util';
import { RequestError } from '@/lowcode/cn-column-chart/components/request-error';
import { EmptyData } from '@/lowcode/cn-column-chart/components/empty-data';
import useFilterSearchListener from 'lowcode-common/hooks/useFilterSearchListener';
import { Loading } from '@/lowcode/cn-column-chart/components/loading';
import { getPieCharOptions } from 'lowcode-common/manager/setter-snippet';
import set from 'lodash/set';
import { FeaturePosition } from 'lowcode-common/types/feature-position';
import isPlainObject from 'lodash/isPlainObject';
import useStopLoop from 'lowcode-common/hooks/useStopLoop';
const CnPieChart = (props) => {
    const { dataFrom, _context, filterConfig, labels, chartStyle } = props || {};
    const { requestConfig } = dataFrom || {};
    const { serviceType, guid, angleField, colorField } = requestConfig || {};
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const realAngleField = angleField?.[0]?.fieldName;
    const realColorField = colorField?.[0]?.fieldName;
    const isDesign = isDesignMode(props);
    const { _bindFilter } = filterConfig || {};
    const { innerRadius, legend } = chartStyle || {};
    const sendRequest = (config) => {
        if (requestConfig && requestConfig.serviceType) {
            const realRequestConfig = makeChartRequestConfig({
                requestConfig,
                _context,
                filterConfig,
                isDesign,
            });
            const { needLoading } = config || {};
            if (needLoading !== false) {
                setLoading(true);
            }
            makeRequest?.(realRequestConfig).then((res) => {
                const data = getRealData(realRequestConfig, res, _context);
                const { value = [] } = data || {};
                setListData(value);
                setLoading(false);
                setError(undefined);
            }, () => {
                setLoading(false);
                setError('请求失败');
            });
        }
    };
    useFilterSearchListener({
        _bindFilter,
        sendRequest,
        _context,
    });
    useStopLoop({
        _nodeId: props?._nodeId,
    });
    const realOptions = {
        ...getPieCharOptions(),
        axis: {
            color: {
                title: '',
                labelAutoHide: true,
            },
        },
        encode: {
            y: realAngleField,
            color: realColorField,
        },
        data: listData,
        tooltip: {
            title: realColorField,
        },
    };
    const realLabels = calculateLabels({
        labels,
        angleField: realAngleField,
        colorField: realColorField,
    });
    if (isArrayNotEmpty(realLabels)) {
        realOptions.labels = realLabels;
    }
    if (!isEmptyButNotZero(innerRadius)) {
        set(realOptions, ['coordinate', 'innerRadius'], innerRadius);
    }
    if (isPlainObject(legend)) {
        set(realOptions, 'legend.color', legend);
    }
    set(realOptions, 'legend.color.title', false);
    const finalOptions = calculateAdvancedConfig({
        props,
        options: calculateFeature({
            props,
            options: realOptions,
            position: FeaturePosition.pie,
            sendRequest,
        }),
        state: _context?.state,
    });
    if (isDesign && loading === undefined) {
        return (<CnChart {...getChartCommonStyle(props)} {...finalOptions} encode={{
                y: 'amount',
                color: 'year',
            }} data={[
                { year: '2022', amount: 111 },
                { year: '2023', amount: 222 },
            ]}/>);
    }
    if (error) {
        return <RequestError error={error}/>;
    }
    console.log(finalOptions, 'finalOptions');
    return (<Loading visible={loading}>
      {judgeDataEmpty(finalOptions) ? (<EmptyData emptyImg={'https://img.alicdn.com/imgextra/i2/O1CN01tmqYml1u4itNfAVMw_!!6000000005984-55-tps-500-260.svg'}/>) : (<CnChart {...getChartCommonStyle(props)} {...finalOptions}/>)}
    </Loading>);
    // return <EmptyData
    //   emptyImg={"https://img.alicdn.com/imgextra/i2/O1CN01tmqYml1u4itNfAVMw_!!6000000005984-55-tps-500-260.svg"}
    // />
};
CnPieChart.displayName = 'CnPieChart';
export default CnPieChart;
export { CnPieChart };
