import React from 'react';
import { CnProgress as UICnProgress } from '@alife/cn-ui';
import { isDesignMode } from 'lowcode-common/util/util';
const CnProgress = (props) => {
    const { _context, chartStyle, percent } = props || {};
    const isDesign = isDesignMode(props);
    return (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <UICnProgress percent={percent} {...chartStyle}/>
    </div>);
};
CnProgress.displayName = 'CnProgress';
export default CnProgress;
export { CnProgress };
