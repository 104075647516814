import React from 'react';
import { CnStatisticCard as BaseCnStatisticCard, clsPrefix, } from 'common/components/cn-statistic-card/index';
import { CnTooltip, CnIcon, CnProgress, CnBox } from '@alife/cn-ui';
import './index.scss';
function CnIconOne({ type, className, color, }) {
    return <CnIcon type={type} className={className} color={color}/>;
}
function CnBoxOne({ spacing, direction, justify, children, }) {
    return (<CnBox spacing={spacing} direction={direction} justify={justify}>
      {children}
    </CnBox>);
}
/**
 * CnStatisticCard 组件
 * @param {*} props
 * @returns
 */
export function CnStatisticCard(props) {
    const renderStatisticTip = (statistic) => {
        if (!statistic?.tip) {
            return null;
        }
        return (<CnTooltip trigger={<div className={`${clsPrefix}-statistic-tip`}>
            <CnIcon type="help-fill" size={14} style={{ color: '#D4DBF2' }}/>
          </div>} align="t">
        {statistic.tip}
      </CnTooltip>);
    };
    const renderProgressBar = (progressConfig) => {
        return (<CnProgress percent={progressConfig.percent} size="medium" color={progressConfig.color} state={progressConfig.state} backgroundColor={progressConfig.backgroundColor} textRender={() => null}/>);
    };
    return (<BaseCnStatisticCard {...props} CnIcon={CnIconOne} renderStatisticTip={renderStatisticTip} CnBox={CnBoxOne} renderProgressBar={renderProgressBar}/>);
}
