import { isArrayNotEmpty } from './util';
function calculatePercent(key, item, data) {
    if (key) {
        const temp = item[key] || '';
        if (typeof temp === 'number') {
            let total = 0;
            if (isArrayNotEmpty(data)) {
                data.forEach((item2) => {
                    if (typeof item2[key] === 'number') {
                        total += item2[key];
                    }
                });
            }
            return `${((temp / total) * 100).toFixed(2)}%`;
        }
    }
    return '';
}
export { calculatePercent };
