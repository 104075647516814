import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CnStatisticCard as UICnStatisticCard } from '@/components/cn-statistic-card';
import { CnBox, CnList } from '@alife/cn-ui';
import { dataOriginDataEasy, dataOriginStatic } from 'lowcode-common/util/const';
import { calculateFeature, calculateTextExprValue, executeFunction, getLowCodeCoreUtil, getRealData, isArrayEmpty, isArrayNotEmpty, isDesignMode, makeChartRequestConfig, setDataToDs, } from 'lowcode-common/util/util';
import isPlainObject from 'lodash/isPlainObject';
import { makeRequest } from 'lowcode-common/util/request';
import { Loading } from '@/lowcode/cn-column-chart/components/loading';
import useFilterSearchListener from 'lowcode-common/hooks/useFilterSearchListener';
import useStopLoop from 'lowcode-common/hooks/useStopLoop';
import { __statisticCard_activeKey__, __statisticCard_clickItem__, __statisticCard_clickKey__, __statisticCard_currentItem__, } from 'lowcode-common/util/expr-const';
import { CnFilterDefaultValueFinished, CnFilterOnSearch, emitEvent, } from 'lowcode-common/util/event';
import { RequestError } from '@/lowcode/cn-column-chart/components/request-error';
import { FeaturePosition } from 'lowcode-common/types/feature-position';
const CnStatisticCard = forwardRef((props, ref) => {
    const { dataFrom, _context, filterConfig, dataOrigin, dataSource, normalStyle, activeKey, _dataSourceName, _dataSource, events, extra, beforeTrendRender, handleStatisticCardListProps, } = props || {};
    const { requestConfig } = dataFrom || {};
    const { customTooltip, cardType, layout, bgColor, mode, col, size } = normalStyle || {};
    const isDesign = isDesignMode(props);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [error, setError] = useState();
    // 首次请求
    const [requestCount, setRequestCount] = useState(0);
    const [tempActiveKeys, setTempActiveKeys] = useState(() => {
        if (dataOrigin !== 'request') {
            let temp;
            if (activeKey) {
                temp = calculateTextExprValue(activeKey, {
                    state: _context?.state,
                    recordDataSource: {},
                });
            }
            if (mode === 'single') {
                return temp?.[0];
            }
            return temp;
        }
    });
    const handleDataSource = () => {
        let result = [];
        if (typeof dataSource === 'function') {
            const ds = calculateTextExprValue(dataSource, {
                state: _context?.state,
                recordDataSource: {},
            });
            if (isArrayNotEmpty(ds)) {
                result = ds;
            }
            else if (isArrayEmpty(ds?.value)) {
                result = ds.value;
            }
        }
        else if (isArrayNotEmpty(dataSource)) {
            dataSource?.forEach?.((item) => {
                const { bgColor, key, ...rest } = item || {};
                const temp = {
                    statistic: { ...rest },
                    key,
                };
                if (bgColor) {
                    temp.bgColor = bgColor;
                }
                result.push(temp);
            });
        }
        return result;
    };
    const [listData, setListData] = useState(() => {
        if (dataOrigin === 'static') {
            const tempData = handleDataSource();
            setTimeout(() => {
                emitEvent(CnFilterDefaultValueFinished, {
                    componentProps: props,
                    formValues: tempData,
                });
            });
            return tempData;
        }
        return [];
    });
    const [tempActiveItems, setTempActiveItems] = useState(() => {
        if (dataOrigin !== 'request') {
            if (mode === 'multiple') {
                return listData?.filter((item) => tempActiveKeys?.includes(item?.key));
            }
            else if (mode === 'single') {
                return listData.find((item) => item?.key === tempActiveKeys);
            }
        }
    });
    const setDataSource = (data) => {
        if (data) {
            setDataToDs({
                _context,
                _dataSource,
                _dataSourceName,
                data,
            });
        }
    };
    useEffect(() => {
        setDataSource({
            [__statisticCard_activeKey__]: tempActiveKeys,
            [__statisticCard_currentItem__]: tempActiveItems,
        });
        calculateFeature({
            props,
            options: {},
            position: FeaturePosition.statisticCard,
            sendRequest,
        });
    }, []);
    useImperativeHandle(ref, () => ({
        reRender() {
            if (dataOrigin === 'static') {
                setListData(handleDataSource());
            }
            setForceUpdate(Date.now());
        },
    }));
    useEffect(() => {
        if (dataOrigin === 'static' && isDesign) {
            setLoading(false);
            setListData(handleDataSource());
        }
    }, [dataSource, dataOrigin]);
    const [loading, setLoading] = useState(() => {
        if (dataOrigin === 'static') {
            return false;
        }
    });
    const { serviceType, guid, fieldList } = requestConfig || {};
    const { _bindFilter } = filterConfig || {};
    const sendRequest = (config) => {
        if (requestConfig && requestConfig.serviceType) {
            const realRequestConfig = makeChartRequestConfig({
                requestConfig,
                _context,
                filterConfig,
                isDesign,
            });
            const { needLoading } = config || {};
            if (needLoading !== false) {
                setLoading(true);
            }
            makeRequest?.(realRequestConfig).then((res) => {
                const data = getRealData(realRequestConfig, res, _context);
                let remoteList = [];
                if (serviceType === dataOriginDataEasy) {
                    const { value, activeKey } = data || {};
                    if (isArrayNotEmpty(value) && isPlainObject(value[0])) {
                        const tempData = value[0];
                        if (isArrayNotEmpty(fieldList)) {
                            fieldList.forEach((item) => {
                                const { fieldName } = item;
                                if (fieldName && tempData[fieldName] !== undefined) {
                                    remoteList.push({
                                        statistic: {
                                            title: fieldName,
                                            value: tempData[fieldName],
                                        },
                                    });
                                }
                            });
                            setListData(remoteList);
                        }
                    }
                }
                else {
                    remoteList = data?.value;
                    if (typeof customTooltip === 'function') {
                        remoteList?.forEach((item) => {
                            if (item?.statistic) {
                                const tempTip = executeFunction(customTooltip, item, _context?.state);
                                if (tempTip) {
                                    item.statistic.tip = tempTip;
                                }
                            }
                        });
                    }
                    setListData(remoteList || []);
                }
                const remoteActiveKey = data?.activeKey;
                if (remoteActiveKey !== undefined) {
                    let remoteActiveItem;
                    if (mode === 'multiple') {
                        remoteActiveItem = remoteList?.filter((item) => remoteActiveKey?.includes(item?.key));
                    }
                    else if (mode === 'single') {
                        remoteActiveItem = remoteList.find((item) => item?.key === remoteActiveKey);
                    }
                    setDataSource({
                        [__statisticCard_activeKey__]: remoteActiveKey,
                        [__statisticCard_currentItem__]: remoteActiveItem,
                    });
                    setTempActiveKeys(remoteActiveKey);
                }
                setLoading(false);
                getLowCodeCoreUtil('util.executeEventWithoutJS')?.({
                    eventType: 'onStatisticCardRequestFinish',
                    events,
                    _context,
                    position: getLowCodeCoreUtil('enumMap.ButtonPosition.statisticCardEvent'),
                    recordDataSource: {},
                });
                if (requestCount === 0) {
                    emitEvent(CnFilterDefaultValueFinished, {
                        componentProps: props,
                        formValues: remoteList,
                    });
                }
                setRequestCount(requestCount + 1);
                setError(undefined);
            }, () => {
                setLoading(false);
                setError('请求失败');
                getLowCodeCoreUtil('util.executeEventWithoutJS')?.({
                    eventType: 'onStatisticCardRequestFinish',
                    events,
                    _context,
                    position: getLowCodeCoreUtil('enumMap.ButtonPosition.statisticCardEvent'),
                    recordDataSource: {},
                });
                setRequestCount(requestCount + 1);
            });
        }
    };
    const getActiveKey = (tempKey, item) => {
        let newKeys;
        let newItems;
        if (mode === 'multiple') {
            if (tempActiveKeys?.includes(tempKey)) {
                newKeys = tempActiveKeys.filter((item) => item !== tempKey);
                newItems = tempActiveItems.filter((item) => item?.key !== tempKey);
            }
            else {
                newKeys = [...tempActiveKeys, tempKey];
                newItems = [...tempActiveItems, item];
            }
        }
        else if (mode === 'single') {
            if (tempKey && tempActiveKeys === tempKey) {
                newKeys = undefined;
                newItems = undefined;
            }
            else {
                newKeys = tempKey;
                newItems = item;
            }
        }
        return {
            newKeys,
            newItems,
        };
    };
    const setActiveKey = (tempKey, item) => {
        const { newKeys, newItems } = getActiveKey(tempKey, item) || {};
        setDataSource({
            [__statisticCard_activeKey__]: newKeys,
            [__statisticCard_currentItem__]: newItems,
        });
        setTempActiveKeys(newKeys);
        getLowCodeCoreUtil('util.executeEventWithoutJS')?.({
            eventType: 'onStatisticCardClick',
            events,
            _context,
            position: getLowCodeCoreUtil('enumMap.ButtonPosition.statisticCardEvent'),
            recordDataSource: {},
        });
        emitEvent(CnFilterOnSearch, {
            componentProps: props,
            payload: {},
        });
    };
    useFilterSearchListener({
        _bindFilter,
        sendRequest,
        _context,
    });
    useStopLoop({
        _nodeId: props?._nodeId,
    });
    const listProps = {};
    const listItemStyle = {
        height: '100%',
    };
    const statisticCardProps = {};
    if (bgColor) {
        statisticCardProps.bgColor = bgColor;
    }
    if (typeof beforeTrendRender === 'function') {
        statisticCardProps.beforeTrendRender = (options) => {
            return executeFunction(beforeTrendRender, options, _context?.state);
        };
    }
    if (cardType === 'noBorder') {
        statisticCardProps.noBorder = true;
        statisticCardProps.noPadding = true;
    }
    if (layout === 'flex') {
        if (typeof col === 'number') {
            listItemStyle.padding = '0 8px 8px 0';
            listProps.horizontalConfig = {
                flex: true,
                flexWrap: true,
                flexColSpan: 24 / col,
            };
        }
        else {
            listItemStyle.padding = '0 8px 8px 0';
            listProps.horizontalConfig = {
                flex: true,
                flexWrap: true,
            };
        }
    }
    else if (typeof layout === 'number') {
        listItemStyle.padding = '0 8px 8px 0';
        listProps.horizontalConfig = {
            flex: true,
            flexWrap: true,
            flexColSpan: 24 / layout,
        };
    }
    else if (layout === 'grid') {
        if (size) {
            listProps.horizontalConfig = {
                size,
            };
        }
    }
    const statisticOnClick = (key, item) => {
        if (mode === 'single' || mode === 'multiple') {
            executeFunction(setActiveKey, key, item);
        }
        else {
            setDataSource({
                [__statisticCard_clickKey__]: key,
                [__statisticCard_clickItem__]: item,
            });
            getLowCodeCoreUtil('util.executeEventWithoutJS')?.({
                eventType: 'onStatisticCardItemClick',
                events,
                _context,
                position: getLowCodeCoreUtil('enumMap.ButtonPosition.statisticCardEvent'),
                recordDataSource: {},
            });
        }
    };
    if (error) {
        return <RequestError error={error}/>;
    }
    // let realListProps = {
    //   ...listProps
    // };
    // const temp = mergeHandleProps({
    //   handleProps: handleStatisticCardListProps,
    //   componentProps: realListProps,
    //   _context,
    // });
    // if (isPlainObject(temp)) {
    //   realListProps = temp;
    // }
    if (isDesign &&
        (loading === undefined ||
            (loading === false && dataOrigin === dataOriginStatic && isArrayEmpty(listData)))) {
        return (<CnList {...listProps} dataSource={[{ statistic: { title: '示例数据', value: 0 } }]} renderItem={(item, index) => {
                return (<CnList.Item style={{ listItemStyle }} className={'l2-cn-list-item'} key={index}>
              <UICnStatisticCard {...statisticCardProps} style={{ width: '100%' }} {...item}/>
            </CnList.Item>);
            }}/>);
    }
    const hasClickEvent = events?.find?.((item) => item.name === 'onStatisticCardClick');
    return (<Loading visible={loading}>
      {CnList && (<CnList {...listProps} className={'l2-cn-list-wrap'} dataSource={listData} renderItem={(item, index) => {
                const { key } = item || {};
                let isActive;
                if (mode === 'multiple') {
                    isActive = key && tempActiveKeys?.includes(key);
                }
                else if (mode === 'single') {
                    isActive = key && tempActiveKeys === key;
                }
                statisticCardProps.onClick = statisticOnClick.bind(this, key, item);
                const extraDom = getLowCodeCoreUtil('util.makeButtons')?.({
                    buttons: extra?.map((item2) => {
                        return {
                            ...item2,
                            size: 'small',
                            position: getLowCodeCoreUtil('enumMap.ButtonPosition.statisticCardRightButton'),
                            children: item2?.children,
                            onClick: (e) => {
                                e.stopPropagation();
                                setDataSource({
                                    [__statisticCard_clickKey__]: key,
                                    [__statisticCard_clickItem__]: item,
                                });
                            },
                        };
                    }),
                    _context,
                    state: _context?.state,
                    recordDataSource: item,
                    extraParamList: {
                        recordDataSource: item,
                    },
                });
                if (isArrayNotEmpty(extraDom)) {
                    statisticCardProps.action = (<CnBox spacing={8} direction="row">
                  {extraDom}
                </CnBox>);
                }
                return (<CnList.Item style={listItemStyle} key={index}>
                <UICnStatisticCard active={isActive} hover={hasClickEvent} {...item} {...statisticCardProps} style={{ width: '100%', height: '100%' }}/>
              </CnList.Item>);
            }}/>)}
    </Loading>);
});
CnStatisticCard.displayName = 'CnStatisticCard';
export default CnStatisticCard;
export { CnStatisticCard };
