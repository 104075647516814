import View from './index';
import { AutoWireViewWithRerender, createOpenUtil } from 'lowcode-common/util/util';
import packageJson from '../../package.json';
import './view.scss';

createOpenUtil();

console.log(
  `%cCN_UI_CHARTS_LOWCODE %c${packageJson?.version}`,
  'color:#000;font-weight:bold;',
  'color:green;font-weight:bold;',
);

// 装配rerender功能
const ViewWithRerender = AutoWireViewWithRerender(View);

window['cn-ui-charts'] = ViewWithRerender;

export * from '@alife/cn-ui-charts';
