import isPlainObject from 'lodash/isPlainObject';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { __dataSource__, __extraParam__, __record__, __tableCurrentRow__, dataOriginDataEasy, formUrlencoded, getQueryDataPath, preMokelayDomain, getDataEasyParams, } from './const';
import qs from 'query-string';
import { handleResultProcessFunc } from './request';
import { CnMessage } from '@alife/cn-ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { componentMap as featureComponentMap } from '../manager/feature';
import { calculatePercent } from './algorithm';
import React from 'react';
import DataSourceSetter from '../setter/datasource-setter/datasource-setter';
import set from 'lodash/set';
import useFilterSearchListener from '../hooks/useFilterSearchListener';
import { customAlphabet } from 'nanoid';
const { VisualEngine } = window;
const Engine = VisualEngine;
const messageTypeMap = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    notice: 'notice',
    help: 'help',
    loading: 'loading',
};
function getRealRequestUrl(config) {
    const { requestConfig, state, extraParam } = config || {};
    let result;
    if (requestConfig) {
        const { dynamicUrl, useDynamicUrl, url } = requestConfig;
        result = url;
        if (useDynamicUrl === true && typeof dynamicUrl === 'function') {
            const temp = executeFunction(dynamicUrl, state, extraParam || {});
            if (typeof temp === 'string') {
                result = temp;
            }
            else {
                result = '';
            }
        }
    }
    return result;
}
export function executeFunction(func, ...rest) {
    if (typeof func === 'function') {
        let result;
        try {
            result = func(...rest);
        }
        catch (e) { }
        return result;
    }
    return func;
}
function isDesignMode(props) {
    let mode;
    if (isPlainObject(props)) {
        mode = props?.__designMode;
    }
    else if (typeof props === 'string') {
        mode = props;
    }
    return mode === 'design';
}
function isArrayNotEmpty(list) {
    return Array.isArray(list) && list.length > 0;
}
function isArrayEmpty(list) {
    return Array.isArray(list) && list.length === 0;
}
function judgeDataEmpty(options) {
    if (options) {
        if (isArrayEmpty(options?.data)) {
            return true;
        }
        if (isArrayEmpty(options.children?.[0]?.data)) {
            return true;
        }
    }
}
function getRealResponse(res = {}) {
    const { status, data } = res;
    let result = res;
    if (status === 200 && data) {
        result = data;
    }
    return result;
}
function transRequestConfigToRemote(config = {}) {
    const { requestConfig, handleParams, isDesign, urlParamsDataSource, recordDataSource, state, extraParamList, getExtraParam, needFormatResult, handleParamsBeforeSend, } = config;
    if (requestConfig) {
        const realUrl = getRealRequestUrl({
            requestConfig,
            state,
            extraParam: getExtraParam?.(),
        });
        const { url, params = [], serviceType, method, contentType, } = requestConfig;
        const temp = {
            url,
            method: 'post',
            data: {},
        };
        if (realUrl) {
            temp.url = realUrl;
        }
        if (isDesign && serviceType === 'mokelay') {
            temp.url = handleDesignMokelayUrl(temp.url);
        }
        if (serviceType === 'http') {
            temp.withCredentials = true;
        }
        if (typeof handleParams === 'function') {
            temp.data = handleParams(temp.data);
        }
        const realParams = handleRequestParams(params, {
            urlParamsDataSource,
            recordDataSource,
            state,
            extraParamList,
            getExtraParam,
        });
        if (Object.keys(realParams).length > 0) {
            temp.data = { ...temp.data, ...realParams };
        }
        if (serviceType === 'http') {
            if (method) {
                temp.method = method;
            }
            if (contentType === formUrlencoded) {
                temp.headers = {
                    'content-type': contentType,
                };
                if (temp.method === 'post' && isPlainObject(temp.data)) {
                    temp.data = qs.stringify(temp.data);
                }
            }
        }
        if (typeof handleParamsBeforeSend === 'function') {
            temp.data = handleParamsBeforeSend(temp.data);
        }
        if (temp.method === 'get') {
            temp.params = temp.data;
            delete temp.data;
        }
        if (needFormatResult === true) {
            temp.formatResult = handleResultProcessFunc(requestConfig);
        }
        return temp;
    }
    else {
        return {};
    }
}
// 处理设计态的mokelay请求url
const handleDesignMokelayUrl = (url) => {
    const domain = window.getCnLowcodeStoreItem?.('__next_cone_domain__');
    if (domain && domain?.value) {
        return `${location.protocol}//${domain.value}${url}`;
    }
    return preMokelayDomain + url;
};
export function handleRequestParams(params, config = {}) {
    let result = {};
    if (params && params.length > 0) {
        params.forEach((item) => {
            if (item) {
                const { label, value } = item;
                if (label && value !== undefined) {
                    result[label] = calculateTextExprValue(value, config);
                }
            }
        });
    }
    else if (typeof params === 'function') {
        const temp = calculateTextExprValue(params, config);
        if (isPlainObject(temp)) {
            result = temp;
        }
    }
    return result;
}
export function isValueObjectExpr(obj) {
    return !!(isPlainObject(obj) && obj.group && obj.param);
}
export function calculateTextExprValue(originValue, config = {}) {
    const { urlParamsDataSource, recordDataSource, state, extraParamList = [], tableCurrentRow, extraParam, getExtraParam, } = config;
    if (typeof originValue === 'function') {
        try {
            let extra = extraParamList;
            if (!Array.isArray(extra)) {
                extra = [];
            }
            return originValue(recordDataSource, state, ...extra);
        }
        catch (e) {
            return;
        }
    }
    else if (isValueObjectExpr(originValue)) {
        const { group, param, secondParam, thirdParam } = originValue;
        if (group && param) {
            let result;
            let realExtraParam = extraParam;
            if (typeof getExtraParam === 'function') {
                realExtraParam = executeFunction(getExtraParam);
            }
            const objExprArgMap = {
                [__record__]: recordDataSource,
                [__dataSource__]: state,
                [__tableCurrentRow__]: tableCurrentRow,
                [__extraParam__]: realExtraParam,
            };
            if (secondParam) {
                result = get(objExprArgMap, `${group}.${param}.${secondParam}${thirdParam ? `.${thirdParam}` : ''}`);
            }
            else {
                result = get(objExprArgMap, `${group}.${param}`);
            }
            return result;
        }
    }
    return originValue;
}
function handleResultProcessFunc2(response, requestConfig, extra) {
    const { state } = extra || {};
    let newRes = getRealResponse(response);
    if (requestConfig) {
        const { resultProcessFunc, serviceType, mockData } = requestConfig;
        if (typeof resultProcessFunc === 'function' &&
            (serviceType === 'mokelay' || serviceType === 'http')) {
            try {
                if (isPlainObject(state)) {
                    newRes = resultProcessFunc(newRes, state);
                }
                else {
                    newRes = resultProcessFunc(newRes);
                }
            }
            catch (e) {
                console.error('请求结果回调执行失败', e);
            }
            return newRes;
        }
        else if (serviceType === 'mock' && isPlainObject(mockData)) {
            return mockData;
        }
    }
    return newRes;
}
function handleSuccessMessage(res) {
    const { msg, extra } = res || {};
    const { type } = extra || {};
    let resultType = 'success';
    if (messageTypeMap[type]) {
        resultType = type;
    }
    CnMessage[resultType](msg || '请求成功');
}
function handleI18nLabel(label) {
    if (isPlainObject(label) && label.type === 'cn-i18n') {
        return label.dm || '';
    }
    return label;
}
function getChartCommonStyle(props) {
    const { chartStyle } = props || {};
    return {
        // style:{
        //   // minHeight: '100px',
        //   // height:'200px',
        //   height: chartStyle?.height,
        // },
        containerStyle: {
            // minHeight: '100px',
            // height:'200px',
            height: chartStyle?.height,
        },
    };
}
function getFilterValue(config) {
    const { filterConfig, _context } = config || {};
    const { _bindFilter } = filterConfig || {};
    let result = {};
    if (isArrayNotEmpty(_bindFilter)) {
        for (const item of _bindFilter) {
            if (item) {
                const filterDom = _context?.$(item);
                const { _dataSourceName } = filterDom?.props || {};
                if (_dataSourceName) {
                    result = {
                        ...result,
                        ...transProxyToObject(_context?.state?.[_dataSourceName]),
                    };
                }
            }
        }
    }
    return result;
}
const transProxyToObject = (origin) => {
    const result = {};
    if (typeof origin === 'object') {
        const keys = Object.keys(origin);
        if (keys && keys.length > 0) {
            keys.forEach((key) => {
                result[key] = origin[key];
            });
        }
    }
    return result;
};
function makeChartRequestConfig(config) {
    const { filterConfig, _context, requestConfig, isDesign } = config || {};
    const { serviceType, guid } = requestConfig || {};
    const filterValue = getFilterValue({
        filterConfig,
        _context,
    });
    // makeRequest方法return会一个Promise对象
    const realRequestConfig = {
        // 请求成功后是否toast提示
        needSuccessToast: false,
        buttonConfig: {
            options: {
                requestConfig,
            },
        },
        recordDataSource: {},
        state: _context?.state || {},
    };
    if (serviceType === dataOriginDataEasy) {
        realRequestConfig.buttonConfig.options.requestConfig = {
            ...requestConfig,
            url: getQueryDataPath(isDesign),
            method: 'post',
            serviceType: 'http',
        };
        realRequestConfig.handleParamsBeforeSend = (data) => {
            const params = {
                conditions: {
                    ...filterValue,
                    ...data,
                },
                guid,
            };
            if (isDesign) {
                params['env'] = getDataEasyParams();
            }
            return params;
        };
    }
    else {
        realRequestConfig.handleParams = (data) => {
            return {
                ...data,
                ...filterValue,
            };
        };
    }
    return realRequestConfig;
}
function calculateLabels(config) {
    const { labels } = config || {};
    if (isArrayNotEmpty(labels)) {
        const result = [];
        labels.forEach((item) => {
            const { type, position } = item || {};
            const data = {};
            if (type) {
                let tempV = config?.[type];
                if (type === 'colorField+angleField') {
                    tempV = (d) => {
                        return `${d[config?.colorField] || ''}   ${d[config?.angleField] || ''}`;
                    };
                }
                else if (type === 'anglePercent') {
                    tempV = (item, index, data) => {
                        const key = config?.angleField;
                        return calculatePercent(key, item, data);
                    };
                }
                else if (type === 'colorField+anglePercent') {
                    tempV = (item, index, data) => {
                        return `${item[config?.colorField] || ''}   ${calculatePercent(config?.angleField, item, data) || ''}`;
                    };
                }
                if (tempV) {
                    data.text = tempV;
                }
                position && (data.position = position);
                if (position === 'outside' || position === 'spider') {
                    data.transform = [{ type: 'overlapDodgeY' }];
                }
            }
            if (!isEmpty(data)) {
                result.push(data);
            }
        });
        return result;
    }
}
function getSlotDom(config) {
    const { componentSlot, isDesign, componentProps } = config || {};
    if (componentSlot) {
        if (isArrayNotEmpty(componentSlot)) {
            return componentSlot.map((item, index) => {
                return React.cloneElement(item, componentProps || {});
            });
        }
        return componentSlot;
    }
    return null;
}
function isEmptyButNotZero(value) {
    return value === undefined || value === null || value === '';
}
function getCommonInteraction() {
    return {
        interaction: {
            tooltip: { shared: true },
            elementHighlightByColor: { background: true },
        },
    };
}
export function getItem(componentMap, position, componentName, propKey, extraQueryParams) {
    let result;
    if (componentName) {
        let item = componentMap[componentName];
        if (typeof item === 'function') {
            item = executeFunction(item, { position });
        }
        let flag = true;
        if (item && item.position && item.position.includes(position)) {
        }
        else {
            flag = false;
        }
        if (isPlainObject(extraQueryParams)) {
            for (const key in extraQueryParams) {
                if (key && Array.isArray(item?.[key])) {
                    if (item[key].includes(extraQueryParams[key])) {
                    }
                    else {
                        flag = false;
                    }
                }
            }
        }
        if (flag) {
            if (!item.label) {
                item.label = item.title;
            }
            if (!item.value) {
                item.value = item.componentName;
            }
            if (propKey) {
                result = item[propKey];
            }
            else {
                result = item;
            }
        }
    }
    return result;
}
export function condition(prop, type, primaryKey) {
    return prop?.parent?.parent?.getPropValue?.(primaryKey) === type;
}
export function handlePrototypeCondition(prototypeList = [], componentName, primaryKey) {
    if (prototypeList?.length > 0) {
        return prototypeList.map((item) => {
            let newItem = item;
            if (isPlainObject(item)) {
                newItem = { ...item };
                newItem.__optType = componentName;
            }
            if (!newItem?.condition) {
                newItem.condition = function (prop) {
                    return condition(prop, componentName, primaryKey);
                };
            }
            return newItem;
        });
    }
    return prototypeList;
}
function getItemPrototypeListByPosition(config) {
    const { position, primaryKey, componentMap } = config;
    const defaultList = Object.keys(componentMap);
    const bizComponentNameList = getBizComponentNameList();
    let prototypeList = [];
    const allComponentList = uniq([...defaultList, ...bizComponentNameList]);
    allComponentList.forEach((name) => {
        const item = getItem(componentMap, position, name) || {};
        const { getPrototypeList, configure = [] } = item;
        if (typeof getPrototypeList === 'function') {
            const temp = getPrototypeList(position);
            if (temp && temp.length > 0) {
                prototypeList = [
                    ...prototypeList,
                    ...handlePrototypeCondition(temp, name, primaryKey),
                ];
            }
        }
        else if (configure?.length > 0) {
            prototypeList = [
                ...prototypeList,
                ...handlePrototypeCondition(configure, name, primaryKey),
            ];
        }
    });
    return prototypeList;
}
export function getItemListByPosition(config) {
    const { position, componentMap, extraQueryParams } = config;
    const result = [];
    const defaultList = Object.keys(componentMap);
    const bizComponentNameList = getBizComponentNameList();
    const allComponentList = uniq([...defaultList, ...bizComponentNameList]);
    allComponentList.forEach((name) => {
        const component = getItem(componentMap, position, name, null, extraQueryParams);
        if (component) {
            const { bizInfo = [] } = component;
            if (bizInfo.length > 0) {
                bizInfo.forEach((item) => {
                    const { label, value } = item;
                    const existGroup = result.find((item2) => item2.value === value);
                    if (existGroup) {
                        existGroup?.children.push(component);
                    }
                    else {
                        result.push({
                            title: label,
                            value,
                            children: [component],
                        });
                    }
                });
                return;
            }
            result.push(component);
        }
    });
    return result;
}
// 获取业务组件列表
function getBizComponentNameList() {
    let result = [];
    if (VisualEngine) {
        const componentsMap = VisualEngine?.designer?.getComponentsMap?.();
        if (componentsMap) {
            result = Object.keys(componentsMap);
        }
    }
    return result;
}
export function getItemDefaultProps(componentMap, position, componentName) {
    const func = getItem(componentMap, position, componentName, 'getDefaultProps');
    if (typeof func === 'function') {
        return func();
    }
}
function calculateFeature(config) {
    const { options, props, position, ...rest } = config || {};
    const { __featureConfig__ } = props || {};
    let result = options;
    if (isArrayNotEmpty(__featureConfig__)) {
        for (const item of __featureConfig__) {
            const { name, active } = item || {};
            if (name && active) {
                const handle = getItem(featureComponentMap, position, name, 'handleFeature');
                const tempResult = handle?.({
                    featureItemConfig: item,
                    props,
                    options,
                    position,
                    ...rest,
                });
                if (isPlainObject(tempResult)) {
                    result = tempResult;
                }
            }
        }
    }
    return result;
}
function getJSExpressionPrototype(config) {
    const { type: configType, prototypeConfig } = config || {};
    if (configType === 'handleProps') {
        return getJSExpressionPrototypeTemplate({
            prototypeConfig,
            initialCode: `(options, state) => {
  return options;
}`,
            tip: (<div className="">
          当前函数需要返回 G2 Spec options。
          <div className="">
            请到{' '}
            <a target={'_blank'} href="https://g2.antv.antgroup.com/zh/examples/general/interval/#column" rel="noreferrer">
              G2官网
            </a>{' '}
            生成Spec配置项
          </div>
          <div className="">state：全部的数据，在左侧列表中选择使用。</div>
        </div>),
        });
    }
    else if (configType === 'base') {
        return getJSExpressionPrototypeTemplate({
            prototypeConfig,
            initialCode: `(arg, state) => {
  // return state.urlParams.xxx;
}`,
            tip: `
state：全部的数据，在左侧列表中选择使用。
`,
        });
    }
    else if (configType === 'dynamicChartItem') {
        return getJSExpressionPrototypeTemplate({
            prototypeConfig,
            initialCode: `(record, state) => {
      // return state.urlParams.xxx;
    }`,
            tip: `
            state：全部的数据，在左侧列表中选择使用。
            record：动态图标的item
      `,
        });
    }
    else if (configType === 'props') {
        return getJSExpressionPrototypeTemplate({
            prototypeConfig,
            initialCode: `(oldProps, state) => {
  // 返回额外的props属性即可，返回值将会和原props合并；
  // return {};
}`,
            tip: `
oldProps: 组件的原始oldProps
state：全部的数据，在左侧列表中选择使用。
`,
        });
    }
    else if (configType === 'statisticCardListProps') {
        return getJSExpressionPrototypeTemplate({
            prototypeConfig,
            initialCode: `(oldProps, state) => {
  // 返回额外的props属性即可，返回值将会和原props合并；
  // return {};
}`,
            tip: `
oldProps: 指标卡列表的原始Props
state：全部的数据，在左侧列表中选择使用。
`,
        });
    }
    return {};
}
function getJSExpressionPrototypeTemplate(config) {
    const { title, initialCode, tip, prototypeConfig } = config || {};
    return {
        title: title || '写js表达式',
        componentName: 'CnRowDataSetter',
        props: () => {
            const stateList = getDataSourceListWithAllPath({
                needChildren: true,
                prototypeConfig,
            });
            return {
                list: stateList,
                initialCode,
                tip,
            };
        },
    };
}
function getDataSourceListWithAllPath(config) {
    const { typeList } = config || {};
    const dataSource = VisualEngine?.context?.getManager('dataPool');
    const items = dataSource?.getItems();
    const result = [];
    if (items?.length > 0) {
        items.forEach((item) => {
            const { name, type, config: dsConfig } = item;
            const label = dsConfig?.description || name;
            const ds = {
                text: label,
                label,
                value: `state.${name}`,
            };
            if (Array.isArray(typeList) && typeList.length > 0) {
                if (typeList.includes(type)) {
                    result.push(ds);
                }
            }
            else {
                result.push(ds);
            }
        });
    }
    return result;
}
function calculateAdvancedConfig(config) {
    const { options, props, state } = config || {};
    const { __advancedConfig__ } = props || {};
    const { handleProps } = __advancedConfig__ || {};
    if (typeof handleProps === 'function') {
        const result = executeFunction(handleProps, options, state);
        if (isPlainObject(result)) {
            return result;
        }
    }
    return options;
}
const getDsNameFromVariable = (dsExpr) => {
    let result = '';
    if (dsExpr && dsExpr.variable) {
        result = dsExpr.variable.slice(dsExpr.variable.lastIndexOf('.') + 1);
    }
    return result;
};
const getDataSourceList = (config) => {
    const { typeList, needChildren } = config || {};
    const dataSource = VisualEngine?.context?.getManager('dataPool');
    const items = dataSource?.getItems();
    const result = [];
    if (items?.length > 0) {
        items.forEach((item) => {
            const { id, name, type, config: dsConfig } = item;
            const label = dsConfig?.description || name;
            const ds = {
                text: label,
                label,
                value: name,
                dataSourceId: dsConfig?.id,
                id,
            };
            if (Array.isArray(typeList) && typeList.length > 0) {
                if (typeList.includes(type)) {
                    result.push(ds);
                }
            }
            else {
                result.push(ds);
            }
        });
    }
    return result;
};
const generateIndex = (dataSourceList, componentName, key = 'value') => {
    let index = 2;
    while (dataSourceList.find((item) => item[key] === `${componentName}${index}`)) {
        index++;
    }
    return index;
};
function generateDataSource(config) {
    const { componentName } = config || {};
    if (componentName) {
    }
    let dataSourceList = [];
    dataSourceList = getDataSourceList();
    let index = '';
    const first = dataSourceList?.find((item) => item.value === `${componentName}`);
    if (first) {
        index = generateIndex(dataSourceList, componentName);
    }
    if (Engine) {
        const componentPrototype = Engine?.designer?.getComponentMeta?.(componentName)?.prototype;
        const dataSource = Engine?.context?.getManager('dataPool');
        const ds = dataSource?.addItem({
            description: `${componentPrototype?.options?.title || componentName}${index}的数据源`,
            dpType: 'VALUE',
            id: '',
            initialData: '',
            isGlobal: false,
            name: `${componentName}${index}`,
            protocal: 'VALUE',
        });
        if (ds?.name) {
            return ds;
        }
    }
}
function createDataSourceSetters() {
    return [
        {
            name: '_dataSource',
            title: '数据源',
            display: 'inline',
            supportVariable: false,
            mutator(value) {
                const node = this.getProps().getNode();
                const result = getDsNameFromVariable(value);
                if (result) {
                    node.setPropValue('_dataSourceName', result);
                }
            },
            initialValue(value) {
                const v = this.getValue();
                // if (value) {
                //   return value;
                // }
                // if(Engine?.Exchange?.hoveringNode){
                //   const node = this.getNode();
                //   const {componentName} = node;
                //   const currentDataSource = node.getPropValue('_dataSource');
                const { componentName } = this;
                const currentDataSource = this.getPropValue('_dataSource');
                if (!currentDataSource && !v) {
                    const ds = generateDataSource({ componentName });
                    if (ds?.name) {
                        // this.setPropValue('_dataSourceName',ds.name)
                        this.parent?.setPropValue?.('_dataSourceName', ds.name);
                        return {
                            type: 'variable',
                            variable: `state.${ds.name}`,
                        };
                    }
                }
            },
            setter: <DataSourceSetter />,
        },
        {
            name: '_dataSourceName',
            title: '数据源名称',
            display: 'none',
            // supportVariable: true,
            initialValue() {
                const value = this.parent?.getPropValue?.('_dataSourceName');
                if (!value) {
                    const node = this.getNode();
                    const dsExpr = node?.getPropValue('_dataSource');
                    return getDsNameFromVariable(dsExpr);
                }
                return value;
            },
        },
    ];
}
function getLowCodeCoreUtil(path) {
    return get(window.cnLowCodeUtils, path);
}
function setAliasName(fieldName, aliasName, options) {
    if (fieldName && aliasName && options) {
        set(options, `axis.${fieldName}.title`, aliasName);
    }
}
function getFirstOptions(options) {
    if (isPlainObject(options)) {
        return options;
    }
    else if (isArrayNotEmpty(options)) {
        return options[0];
    }
    return options;
}
function setDataToDs(config) {
    const { data, _dataSourceName, _context, _dataSource } = config || {};
    if (isPlainObject(data) && _dataSourceName && _context) {
        _context?.setState({
            [_dataSourceName]: {
                ..._dataSource,
                ...data,
            },
        });
    }
}
function createOpenUtil() {
    if (!window.cnChartsUtils) {
        window.cnChartsUtils = {
            hook: {
                useFilterSearchListener,
            },
        };
    }
}
function uuid(len = 4) {
    return customAlphabet('abcdefghijklmnopqrstuvwxyz', len)();
}
function getRealData(realRequestConfig, res, _context) {
    let data = res?.data;
    const resultProcessFunc = handleResultProcessFunc(realRequestConfig);
    if (typeof resultProcessFunc === 'function') {
        data = executeFunction(resultProcessFunc, res, _context?.state);
    }
    return data;
}
// 自动装配rerender 方法
function AutoWireRerender(SourceComp) {
    function useUpdate() {
        const [, setState] = React.useState({});
        return React.useCallback(() => {
            setState({});
        }, []);
    }
    const UpdateComp = React.forwardRef((props, ref) => {
        const update = useUpdate();
        React.useImperativeHandle(ref, () => ({
            reRender() {
                update();
            },
        }));
        return <SourceComp {...props}/>;
    });
    class Wrapper extends React.Component {
        ref = React.createRef();
        constructor(props) {
            super(props);
        }
        reRender = () => {
            this.ref?.current?.reRender?.();
        };
        render() {
            return <UpdateComp ref={this.ref} {...this.props}/>;
        }
    }
    hoistNonReactStatics(Wrapper, SourceComp);
    Wrapper.displayName = SourceComp.displayName;
    return Wrapper;
}
// 装配rerender效果配置名单
const rerenderOpenList = [
    'CnPieChart',
    'CnLineChart',
    'CnProgress',
    'CnColumnChart',
];
function AutoWireViewWithRerender(compList) {
    return compList?.map((compItem) => rerenderOpenList.includes(compItem?.displayName)
        ? AutoWireRerender(compItem)
        : compItem);
}
// function mergeHandleProps(config) {
//   const { handleProps, componentProps, _context } = config || {};
//   if (typeof handleProps === 'function') {
//     const tempProps = executeFunction(
//       handleProps,
//       { ...componentProps },
//       _context?.state,
//     );
//     if (isPlainObject(tempProps)) {
//       return merge({ ...componentProps }, tempProps);
//     }
//   }
// }
export { isDesignMode, isArrayNotEmpty, getRealResponse, getRealRequestUrl, handleDesignMokelayUrl, transRequestConfigToRemote, handleResultProcessFunc2, handleSuccessMessage, handleI18nLabel, getChartCommonStyle, getFilterValue, transProxyToObject, makeChartRequestConfig, calculateLabels, getSlotDom, isEmptyButNotZero, getCommonInteraction, getItemPrototypeListByPosition, getBizComponentNameList, calculateFeature, getJSExpressionPrototype, getDataSourceListWithAllPath, calculateAdvancedConfig, createDataSourceSetters, getDataSourceList, isArrayEmpty, judgeDataEmpty, getLowCodeCoreUtil, setAliasName, getFirstOptions, setDataToDs, createOpenUtil, uuid, getRealData, AutoWireRerender, AutoWireViewWithRerender, };
