import { useEffect, useState } from 'react';
import { CnFilterDefaultValueFinished, CnFilterOnSearch, offEvent, onEvent, } from '../util/event';
import { getLowCodeCoreUtil, isArrayNotEmpty } from '../util/util';
import { dataOriginRequest, dataOriginStatic } from '../util/const';
export default function useFilterSearchListener(options) {
    const { _bindFilter, sendRequest, _context } = options || {};
    const [requestFinishedFilter, setRequestFinishedFilter] = useState(() => {
        const needRequestFilterList = [];
        _bindFilter?.forEach?.((item) => {
            const filterDom = _context?.$(item);
            const isCnStatisticCard = filterDom?.props?.isCnStatisticCard;
            const isFilterCondition = filterDom?.props?.isFilterCondition;
            if (isCnStatisticCard || isFilterCondition) {
                needRequestFilterList.push(item);
            }
            else {
                const dataOrigin = filterDom?.props?.defaultParams?.dataOrigin;
                if (dataOrigin === dataOriginRequest) {
                    needRequestFilterList.push(item);
                }
                else if (dataOrigin === dataOriginStatic) {
                    const tempResult = getLowCodeCoreUtil('util.calculateWaitComponentList')?.(filterDom?.props?.config, filterDom?.props?.defaultParams);
                    if (isArrayNotEmpty(tempResult?.waitComponentList)) {
                        needRequestFilterList.push(item);
                    }
                }
            }
        });
        return needRequestFilterList;
    });
    useEffect(() => {
        if (isArrayNotEmpty(_bindFilter)) {
            onEvent(CnFilterOnSearch, (item) => {
                const { componentProps, payload } = item || {};
                const { _nodeId } = componentProps || {};
                if (_nodeId && _bindFilter.indexOf(_nodeId) !== -1) {
                    sendRequest?.();
                }
            });
            onEvent(CnFilterDefaultValueFinished, (item) => {
                const { componentProps, payload } = item || {};
                const { _nodeId } = componentProps || {};
                setRequestFinishedFilter((oldFilter) => {
                    if (oldFilter?.indexOf(_nodeId) !== -1) {
                        return oldFilter?.filter?.((item2) => item2 !== _nodeId);
                    }
                    else {
                        return oldFilter;
                    }
                });
            });
            return () => {
                offEvent(CnFilterOnSearch);
                offEvent(CnFilterDefaultValueFinished);
            };
        }
    }, []);
    useEffect(() => {
        if (requestFinishedFilter?.length === 0) {
            sendRequest?.();
        }
    }, [requestFinishedFilter]);
}
