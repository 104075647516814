import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import './index.scss';
const titleClsPrefix = 'cn-tile-title';
/**
 * CnTile 组件
 * @param {*} props
 * @returns
 */
function CnTile(props) {
    const { className, style, headStyle = {}, bodyStyle = {}, title, titleTip, titleSlot, extra, collapsible, onCollapse, defaultCollapsed = false, children, size = 'medium', border = false, renderCollapsibleIcon, renderTitleTip, } = props;
    const [collapsed, setCollapsed] = useState(defaultCollapsed);
    const hasHead = useMemo(() => {
        return extra || title || collapsible;
    }, [collapsible, extra, title]);
    const toggleCollapsed = () => {
        onCollapse?.(!collapsed);
        setCollapsed && setCollapsed(!collapsed);
    };
    // 标题区域
    const headTitle = () => {
        if (!hasHead) {
            return null;
        }
        return (<div className={`${titleClsPrefix}`}>
        {collapsible && (<div className={`${titleClsPrefix}-collapsed-icon`} onClick={toggleCollapsed}>
            {renderCollapsibleIcon(collapsed)}
          </div>)}
        {title && <div className={`${titleClsPrefix}-content`}>{title}</div>}
        {titleTip && (<div className={`${titleClsPrefix}-tip`} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
            {renderTitleTip()}
          </div>)}
        {titleSlot}
      </div>);
    };
    // 右上角工具区域
    const toolTip = () => {
        if (!extra) {
            return null;
        }
        return (<div className="cn-tile-header-tool">
        <div className="right-slot">
          {/* 右上角菜单区域 */}
          {extra}
        </div>
      </div>);
    };
    return (<div className={classNames('cn-tile', {
            'cn-tile-small': size === 'small',
            'cn-tile-collapsed': collapsed,
            'cn-tile-no-head': !hasHead,
            border,
            shadow: !border,
        }, className)} style={style}>
      {hasHead && (<div className="cn-tile-header" style={headStyle}>
          {headTitle()}
          {toolTip()}
        </div>)}
      <div className={classNames('cn-tile-body')} style={bodyStyle}>
        {children}
      </div>
    </div>);
}
export { CnTile };
