const params = new URLSearchParams(location.search);
const pageId = params.get('pageId');
export const preMokelayDomain = 'https://pre-cn-x-gateway.cainiao.com';
export const preDataEasyDomain = 'https://pre-cn-x-gateway.cainiao.com';
export const dailyDataEasyDomain = 'https://cn-x-gateway.cainiao.test';
export const prodDataEasyDomain = 'https://cn-x-gateway.cainiao.test';
// 运行时用这个
// export const queryDataSetByPage = '/cone/buc-for-l4/queryDataSetByPage';
export const queryDataSetByPage = '/api/tg/queryDataSetByPage';
export function getQueryDataPath(isDesign) {
    // return '/cone/buc-for-l4/queryData';
    if (isDesign) {
        // 设计态用这个
        return '/api/tg/queryData';
    }
    else {
        // 运行走网关接口
        return `/${window._GATEWAY_PF?.tenantCode}/${window._GATEWAY_PF?.appPath}/queryData`;
    }
}
export function getDataEasyDomain() {
    // return 'https://nextcone.cainiao.test';
    // return 'https://pre-next.cone.cainiao-inc.com'
    return '';
    // const envKey = '__next_cone_env__';
    // const item = localStorage.getItem(envKey + pageId);
    // let env = 'pre';
    // if (item) {
    //   try {
    //     if (typeof item === 'string') {
    //       env = item;
    //     } else if (typeof item === 'object') {
    //       env = JSON.parse(item);
    //     }
    //   } catch (err) {}
    // }
    // if (env === 'daily') {
    //   return dailyDataEasyDomain;
    // } else if (env === 'prod') {
    //   return prodDataEasyDomain;
    // }
    // return preDataEasyDomain;
}
export function getDataEasyParams() {
    let env = 'pre';
    const envKey = '__next_cone_env__';
    const item = localStorage.getItem(envKey + pageId);
    if (item) {
        try {
            if (typeof item === 'string') {
                env = item;
            }
            else if (typeof item === 'object') {
                env = JSON.parse(item);
            }
        }
        catch (err) {
            // ignore
        }
    }
    return env;
}
export const FUSION_UI_VIEW_PREFIX = 'CnLayout-layout-view';
export const PAGE = 'CnLayoutPage';
export const PAGE_HEADER = 'CnLayoutHeader';
export const PAGE_FOOTER = 'CnLayoutFooter';
export const PAGE_NAV = 'CnLayoutNav';
export const PAGE_ASIDE = 'CnLayoutAside';
export const PAGE_CONTENT = 'CnLayoutContent';
export const SECTION = 'CnLayoutSection';
export const BLOCK = 'CnLayoutBlock';
export const TILE = 'CnTile';
export const CELL = 'CnLayoutCell';
export const ROW = 'CnLayoutRow';
export const COL = 'CnLayoutCol';
export const GRID = 'CnLayoutGrid';
export const P = 'CnLayoutP';
export const FIXED_POINT = 'CnLayoutFixedPoint';
export const FIXED_CONTAINER = 'CnLayoutFixedContainer';
export const dataOriginDataEasy = 'dataeasy';
export const formUrlencoded = 'application/x-www-form-urlencoded';
export const AfterRequestSuccess = 'AfterRequestSuccess';
export const __urlParams__ = '__urlParams__';
export const __record__ = '__record__';
export const __ds__ = '__ds__';
export const __blank__ = '__blank__';
export const __tableCurrentRow__ = '__tableCurrentRow__';
export const __dataSource__ = '__dataSource__';
export const __extraParam__ = '__extraParam__';
export const dataOriginStatic = 'static';
export const dataOriginRequest = 'request';
// 增强配置项
export const __featureConfig__ = '__featureConfig__';
// 高级配置关键词
export const __advancedConfig__ = '__advancedConfig__';
export const statisticCardDs = [
    {
        label: '蓝色',
        value: '#DEF1FD',
    },
    {
        label: '绿色',
        value: '#ECFAF2',
    },
    {
        label: '灰色',
        value: '#F2F2F6',
    },
    {
        label: '紫色',
        value: '#F7F0FE',
    },
    {
        label: '红色',
        value: '#FDF1F3',
    },
    {
        label: '黄色',
        value: '#FEF8E2',
    },
    {
        label: '无背景色',
        value: undefined,
    },
];
