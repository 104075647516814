import React, { forwardRef } from 'react';
import { CnChart as BaseCnChart, ChartProps } from 'common/components/cn-chart/index';
/**
 * CnChart 组件
 * @param {*} props
 * @returns
 */
const CnChart = forwardRef((props, ref) => {
    return <BaseCnChart {...props} ref={ref}/>;
});
CnChart.displayName = 'CnChart';
export { CnChart, ChartProps };
